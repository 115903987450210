@use 'settings/global' as *;
@use 'tools' as *;

.topnav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.topnav-list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
}

.topnav-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 0 1 auto;

    transition: background-color 0.1s;

    @include mq(0px, 'mobilenav') {
        & + & {
            margin-left: $spacing-md;
        }

        &:last-child {
            margin-left: auto;
        }
    }

    @include mq('mobilenav') {
        & + & {
            margin-left: $spacing-lg;
        }
    }
}

// .topnav-icon {
//     font-size: $fontsize-body-md;
//     font-weight: 400;
//     color: $color-secondary;
//     margin-right: 0.4rem;
// }

.topnav-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    text-align: left;
    text-decoration: none;
    background-color: transparent;
    transition: color 0.1s, background-color 0.1s;

    @include mq(0px, 'mobilenav') {
        color: $white;

        &:hover, &:focus {
            color: $color-primary-darkest;

            > .icon {
                color: inherit;
            }
        }

        &:active {
            color: $color-primary-darkest;

            > .icon {
                color: inherit;
            }
        }
    }

    @include mq('mobilenav') {
        display: flex;
        align-items: center;
        padding: 0;

        height: 100%;
        width: auto;
        color: $color-text;
        z-index: 0;

        &:hover, &:focus {
            color: $color-primary-light;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 0.4em;
        }

        &:active {
            color: transparent;
        }

        // .topnav-item.has-children & {
        //     * + * {
        //         margin-left: $spacing-sm;
        //     }
        // }

        // & + & {
        //     margin-left: 0.2rem;
        // }
    }
}

.topnav-icon {
    position: relative;
    top: -1px;
    color: $color-text;
    //     display: none;
    //     margin-left: $spacing-sm;

    //     @include mq('mobilenav') {
    //         display: inline-block;
    //     }
}

button.topnav-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    overflow: visible;
    cursor: pointer;
    border: 0;
    background-color: transparent;

    &:active {
        background-color: transparent;
    }
}