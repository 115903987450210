@use 'settings/global' as *;
@use 'settings/form-config' as *;
@use 'tools' as *;

.integer-input {
    position: relative;
    text-align: center;

    &[type="number"] {
        appearance: textfield;
        padding-left: $form-height;
        padding-right: $form-height;
        text-align: center;

        /* Remove arrows/spinners from number input */
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }
}

.integer-input-controls {}

.integer-input-increase,
.integer-input-decrease {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;

    position: absolute;
    top: 1rem;
    bottom: 1rem;
    width: 2.6rem;
    height: $form-height - 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;

    background-color: $color-primary;
    border: $form-border $color-primary;
    border-radius: $borderradius-md;

    z-index: 1;
    cursor: pointer;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover, &:active {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
        color: $white;
    }

    &:active:not(:disabled) {
        padding-top: 0.2rem;
    }

    &:disabled {
        background-color: $color-subsidiary-light;
        border-color: $color-subsidiary-light;
        color: $color-text;
        cursor: not-allowed;
        opacity: 0.75;

        &:hover, &:focus, &:active {
            background-color: $color-subsidiary-light;
            border-color: $color-subsidiary-light;
            color: $color-text;
        }
    }
}

.integer-input-increase {
    right: 1rem;
}

.integer-input-decrease {
    left: 1rem;
}