@use 'settings/global' as *;
@use 'tools' as *;

.cookienotice-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    background-color: $color-overlay;
}

.cookienotice {
    // margin-left: auto;
    // margin-right: auto;

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 110;

    width: calc(100% - ($spacing-md * 2));
    transform: translate(-50%, -50%);

    @include mq(700px) {
        max-width: 70rem;
        width: 100%;
    }

    &:focus-visible {
        outline: 2px solid $color-secondary-light;
        outline-offset: 2px;
        border-radius: $borderradius-md;
    }
}

.cookienotice-content {
    // padding-top: $spacing-md;
    // padding-bottom: $spacing-md;
    display: flex;
    flex-flow: column nowrap;

    background-color: $white;
    border-radius: $borderradius-md;
    border-bottom: 2px solid $color-primary;
    box-shadow: $boxshadow;

    @supports (max-height: 100svh) {
        max-height: calc(100svh - #{$spacing-lg});
    }

    @supports not (max-height: 100svh) {
        max-height: 90vh;
    }

    @include mq('md') {
        padding: $spacing-md;
    }
}

.cookienotice-body {
    padding: $spacing-md;
    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-style: normal;
    overflow-y: auto;
}

.cookienotice-headline {
    font-size: $fontsize-heading-xl;
}

.cookienotice-check {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: $spacing-sm;

        width: $spacing-md;
        height: $spacing-md;

        &:focus-visible {
            @include focus-visible();
        }
    }
}

.cookienotice-label {
    font-weight: bolder;
}

.cookienotice-description {
    margin-left: $spacing-lg;
}

.cookienotice-actions {
    padding-top: $spacing-md;
    padding-bottom: $spacing-md;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    // margin: 0 (-$spacing-md);
    // padding: $spacing-md $spacing-md 0 $spacing-md;
    border-top: 1px solid $color-subsidiary-lightest;
    box-shadow: 0px -8px 16px rgba($black, 0.08);

    @include mq('md') {
        margin-left: -$spacing-md;
        margin-right: -$spacing-md;
        padding-top: $spacing-lg;
        padding-bottom: $spacing-md;
        padding-left: $spacing-xl;
        padding-right: $spacing-xl;
        // padding: $spacing-lg ($spacing-xl) $spacing-md ($spacing-xl);
    }

    > .button {
        margin-right: $spacing-md;

        .cookienotice-banner & {
            display: block;
        }
    }

    // .cookienotice-banner & {
    //     > .button {
    //         display: block;
    //     }
    // }

    // .cookienotice-modal & {
    // @include mq('md') {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    // .button {
    //     display: inline-block;
    //     margin-right: $spacing-md;
    // }
    // }
}

.cookienotice-link {
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: auto;
    border: 0;
    overflow: visible;
    background: transparent;
    color: $color-primary;
    font-size: $fontsize-body-md;
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
    text-transform: none;
    cursor: pointer;

    &:hover, &:focus {
        color: $color-primary-dark;
        text-decoration: none;
    }

    &:active {
        color: $color-primary-darkest;
        text-decoration: underline;
    }

    .cookienotice-actions & {
        margin-top: $spacing-md;

        &:not(:last-child) {
            margin-right: $spacing-md;
        }

        .cookienotice-modal & {
            @include mq('md') {
                margin-top: 0;
            }
        }
    }
}