@use 'settings/global' as *;
@use 'tools' as *;

.cardgrid {
    @include mq('xs') {
        display: grid;
        gap: $spacing-md;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq('lg') {
        &.cardgrid--narrow {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @include mq('xl') {
        gap: $spacing-xl;
    }
}

.cardgrid-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-bottom: $spacing-md;

    &:last-child {
        margin-bottom: 0;
    }

    @include mq('xs') {
        margin-bottom: 0;
    }
}

.card {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    padding: $spacing-sm;
    border-radius: $borderradius-md;
    border: 1px solid $border-color;

    // &:hover, &:focus {
    //     span.a {
    //         text-decoration: none;
    //     }
    // }

    // &:active {
    //     span.a {
    //         color: $color-primary-darkest;
    //         text-decoration: underline;
    //         text-decoration-thickness: 2px;
    //     }
    // }
}

.card-body {
    padding: $spacing-md;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    order: 2;
    font-size: $fontsize-body-sm;

    // overwrites default type-slist ::before size;
    ul {
        > li {
            &::before {
                height: 1.8rem;
                width: 1.8rem;
            }
        }

        // li + li {
        //     margin-top: 0;
        // }
    }
}

.card-headline {
    display: block;
    font-size: $fontsize-heading-xs;
    font-family: $fontfamily-heading;
    font-weight: 400;
    color: $color-text;
    text-transform: none;
    margin-bottom: $spacing-md;

    > .card-link {
        &:hover, &:focus {
            color: $color-primary;
        }

        &:active {
            color: $color-primary-dark;
        }
    }
}

.card-link {
    display: block;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}

.card-footer {
    margin-top: auto;
}

.card-badge {
    margin-bottom: $spacing-sm;

    .icon {
        color: $color-success;
    }
}

.card-action {
    position: relative;
    z-index: 10;
}

.card-media {
    position: relative;
    display: block;
    order: 1;

    overflow: hidden;
    height: 20rem;

    > img {
        display: block;
        height: 100%;
        max-height: 26rem;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        object-fit: contain;
    }
}

// .card-image {
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     height: 100%;
//     max-height: 26rem;

//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     object-fit: contain;
// }