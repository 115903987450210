@use 'settings/global' as *;

html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    tab-size: 4;

    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%; // prevent adjustments of font size after orientation changes in iOS.

    visibility: visible !important; // prevent FOUC (Flash of Unstyled Content)
}

body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    line-height: $lineheight-body;
    color: $color-text;

    background-color: $white;

    &.no-scroll,
    &.has-open-navigation {
        position: fixed; // fixed is set for strange issues in Chrome when opening navigation
        width: 100%;
        height: 100%;
        // height: 100vh;
        overflow: hidden;
        touch-action: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: none;
        -ms-scroll-chaining: none;

        // position: fixed;
        // width: 100%;
        // height: 100%;
        // overflow: hidden;
    }

    // > div#__nuxt {
    //     height: 100%;
    //     // min-height: 100%;
    // }
}