@use 'settings/global' as *;
@use 'tools' as *;

/// Form defaults
input, textarea {
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &::-webkit-input-placeholder {
        color: $color-text-light;
    }

    &:-moz-placeholder {
        color: $color-text-light;

    }

    &::-moz-placeholder {
        color: $color-text-light;
    }

    &:-ms-input-placeholder {
        color: $color-text-light;

    }

    &:-webkit-autofill:first-line {
        font-size: $fontsize-body-md;
    }
}

[placeholder] {
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transition: opacity 0.5s 0.5s ease;
    }

    &:focus::-moz-placeholder {
        opacity: 0;
        transition: opacity 0.5s 0.5s ease;
    }
}

button,
[type="button"] {
    &:focus-visible {
        @include focus-visible();
        outline-offset: 1px;
    }
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 6px 0 0;
    line-height: normal;
}

input[type="search"] {
    appearance: none;
    -webkit-appearance: none;
}

// input[type="search"]::-webkit-search-decoration,
// input[type="search"]::-webkit-search-cancel-button,
// input[type="search"]::-webkit-search-results-button,
// input[type="search"]::-webkit-search-results-decoration {
//     display: none;
// }