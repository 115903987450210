@use 'settings/global' as *;
@use 'tools' as *;
@use 'components/core/button' as *;

.basket {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;

    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        bottom: 0;
        border-left: 2px solid $border-color;
    }
}

.basket-dialpad {
    margin-top: auto;
}

.basket-actions {
    margin-top: $spacing-md;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;
}

// .basket-button-remove {
//     @include button-reset();

//     color: $white;
//     // border-radius: 50%;
//     background-color: $color-danger;

//     &:hover, &:focus {
//         background-color: $color-danger-dark;
//     }

//     &:active {
//         background-color: $color-danger-darker;
//     }
// }
