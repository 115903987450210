@use 'settings/global' as *;
@use 'tools' as *;

$tabs-button-height: 4rem !default;
$tabs-button-lineheight: 1.1 !default;
$tabs-button-fontfamily: $fontfamily-body;
$tabs-button-fontsize: $fontsize-body-md !default;
$tabs-button-padding-y: calc(($tabs-button-height - ($tabs-button-fontsize * $tabs-button-lineheight)) / 2) !default;
$tabs-button-padding-x: calc($tabs-button-height / 2) !default;
$tabs-button-borderradius: 0.5rem !default;
$tabs-button-borderwidth: 0.2rem;

.tabs {
    // background-color: rgba($color-primary-lightest, 0.4);
    border-radius: $borderradius-md;
    padding-bottom: $spacing-md;
}

.tabs-list {
    margin-bottom: 0;
    margin-left: -$spacing-xs; // done for correct visible :focus-visible element when tabbing;
    // padding-bottom: $spacing-md;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    // overflow-x: auto;
    // scroll-behavior: smooth;
}

.tabs-item {
    flex: 0 0 auto;
    margin-top: 0;
    margin-bottom: $spacing-md;
    margin-left: $spacing-xs;
    margin-right: $spacing-md;
}

.tabs-button {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    border: 0;
    border-radius: $tabs-button-borderradius;
    background-color: transparent;
    color: $color-text-light;

    font-family: $tabs-button-fontfamily;
    font-size: $tabs-button-fontsize;
    font-weight: 600;
    font-style: normal;
    line-height: $tabs-button-lineheight;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    text-shadow: none;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover {
        text-decoration: none;
        color: $color-primary;
        // background-color: $color-subsidiary-light;
    }

    &:focus-visible {
        outline-offset: 2px;
    }

    &[aria-selected='true'] {
        color: $color-text;
        // background-color: $color-subsidiary-lightest;

        &:hover {
            text-decoration: none;
            color: $color-primary;
            // background-color: $color-subsidiary;
        }
    }
}

.tabs-panels {
    // padding-left: $spacing-md;
    // padding-right: $spacing-md;
}

.tabs-panel {
    :last-child {
        margin-bottom: 0;
    }
}