@use 'settings/global' as *;
@use 'tools' as *;

.buttonstack {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    text-align: left;

    > .button {
        flex: 0 1 auto;

        & + .button {
            margin-top: $spacing-md;
        }
    }

    // &.buttonstack--full {
    //     align-items: stretch;
    // }
}