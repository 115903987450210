@use 'settings/global' as *;
@use 'tools' as *;

.tilegrid {
    @include mq('sm') {
        display: grid;
        gap: $spacing-md;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq('lg') {
        gap: $spacing-xl;
    }
}

.tilegrid-item {
    margin-bottom: $spacing-xl;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:last-child {
        margin-bottom: 0;
    }

    > .tile {
        flex: 0 1 auto;
        width: 100%;
    }
}

.tile {
    display: flex;
    flex-flow: column nowrap;
    // padding: $spacing-md;

    > * {
        margin-bottom: $spacing-md;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.tile-header {
    margin-bottom: $spacing-lg;
}

.tile-icon {
    width: 4rem;
}

.tile-headline {
    display: block;
    font-family: $fontfamily-heading;
    font-size: $fontsize-heading-md;
    font-weight: 700;
    color: $color-text;
    // text-transform: uppercase;
    margin-bottom: $spacing-md;

    @include mq('md') {
        font-size: $fontsize-heading-md;
    }
}

.tile-body {
    margin-bottom: $spacing-md;

    > :last-child {
        margin-bottom: 0;
    }
}

.tile-blockquote {
    padding-left: 0;
    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    color: $color-primary-dark;
    padding-top: $spacing-md;
    // margin-top: 0;
    margin: 0;

    &::before {
        position: static;
        margin-left: 0;
        display: block;
        text-align: left;
        line-height: 0;
        font-size: $fontsize-heading-2xl;
    }
}

.tile-footer {
    margin-top: auto;
}

.tile-action {
    align-self: end;
}