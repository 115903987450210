@use 'settings/global' as *;
@use 'tools' as *;

.mainnav-container {
    @include mq(0px, 'mobilenav') {
        display: flex;
        flex-flow: column nowrap;

        position: fixed;
        top: 0;
        left: 100%;
        z-index: -1;

        width: 100%;
        height: 100%;
        max-width: 40rem;

        overflow: auto;

        padding-top: $mobile-header-height;
        background-color: $white;

        visibility: hidden;
        opacity: 0;
        transform: translateX(0);
        will-change: translateX;
        -webkit-overflow-scrolling: touch;

        transition: transform 0.3s, visibility 0.3s;

        .has-open-navigation & {
            transform: translateX(-100%);
            visibility: visible;
            opacity: 1;
            box-shadow: -0.2rem 1rem 1rem rgba($black, 0.2);
        }

        > .topnav {
            display: none;
        }
    }

    @include mq('mobilenav') {
        flex: 0 1 auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;

        > .topnav {
            order: -1;
            padding-bottom: $spacing-xl;
        }
    }
}

.mainnav {
    @include mq(0px, 'mobilenav') {
        width: 100%;
        background-color: transparent;
    }

    @include mq('mobilenav') {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-end;
    }
}

.mainnav-list {
    list-style: none;
    margin: 0;
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;

    @include mq('mobilenav') {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0;
    }
}

.mainnav-item {
    position: relative;
    display: block;
    white-space: nowrap;

    @include mq(0px, 'mobilenav') {
        & + & {
            margin-top: $spacing-sm;
        }

        > .button {
            width: 100%;
            margin-top: $spacing-md;
        }
    }

    @include mq('mobilenav') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 0 1 auto;
        margin-right: $spacing-md;

        transition: background-color 0.1s;

        > .button {
            white-space: nowrap;
        }

        &.has-children {
            position: relative;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @include mq('xl') {
        margin-right: $spacing-md;

        &:last-child {
            margin-right: 0;
        }
    }
}

.mainnav-link {
    position: relative;
    display: block;
    z-index: 0;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-lg;
    font-weight: $fontweight-body;
    text-align: left;
    text-decoration: none;
    color: $color-text;

    transition: color 0.1s, background-color 0.1s, border-color 0.1s;

    @include mq(0px, 'mobilenav') {
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        border-radius: $borderradius-md;

        &:hover, &:focus {
            text-decoration: none;
            color: $white;
            background-color: $color-primary-light;
        }

        &:active {
            text-decoration: none;
            color: $white;
            background-color: $color-primary-light;
        }

        .mainnav-item.is-active > & {
            color: $color-primary;

            &:hover, &:focus {
                color: $color-primary-dark;
            }

            &:active {
                color: $color-primary-darkest;
            }
        }

        // .mainnav-item.has-children > & {
        //     padding-left: ($spacing-md) + 5rem;
        // }
    }

    @include mq('mobilenav') {
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: $spacing-xl;
        border-bottom: 3px solid transparent;

        &:hover, &:focus {
            text-decoration: none;
            color: $color-primary-light;
            background-color: transparent;
            border-bottom: 3px solid $color-primary-light;
        }

        &:active {
            text-decoration: none;
            color: transparent;
            background-color: transparent;
        }

        .mainnav-item.is-active > & {
            font-weight: 700;
            color: $color-text;
            background-color: transparent;
            border-bottom: 3px solid $color-primary-light;

            &:hover, &:focus {
                color: $color-primary-light;
                background-color: transparent;
            }

            &:active {
                color: transparent;
                background-color: transparent;
            }
        }
    }
}