@use 'settings/global' as *;
@use 'tools/assets-helper' as *;
@use 'tools/mq' as *;

/// Mixins
@mixin visually-hidden() {
    &:not(:focus):not(:active) {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        white-space: nowrap;
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden;
    }
}

@mixin icon-before($name, $size: 1.6rem) {
    @supports (mask-image: url()) {
        &::before {
            content: "";
            display: inline-block;
            width: $size;
            height: $size;
            mask-image: icon($name);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 100% 100%;
            background-color: currentColor;
        }
    }
}

@mixin icon($name, $size: 1.6rem) {
    @supports (mask-image: url()) {
        &::after {
            content: "";
            display: inline-block;
            width: $size;
            height: $size;
            mask-image: icon($name);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 100% 100%;
            background-color: currentColor;
        }
    }
}

/*
 * Word wrap & ellipsis mixins
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin focus-visible() {
    text-decoration: none;
    outline: 2px solid $color-primary-darkest;
    outline-offset: 3px;
    // border-radius: $borderradius-md;
    z-index: 1;
}

@mixin max-width-breakpoints() {
    max-width: $screen-xs;

    @include mq ('sm') {
        max-width: $screen-sm;
    }

    @include mq('md') {
        max-width: $screen-md;
    }

    @include mq('lg') {
        max-width: $screen-lg;
    }

    @include mq('xl') {
        max-width: $screen-xl;
    }
}
