@use 'settings/global' as *;
@use 'tools' as *;
@use 'components/core/button' as *;

$datepicker-column-width: 4rem;

// .datepicker-container {}

.datepicker {
    background-color: $white;
    border-radius: $borderradius-md;
    border: 1px solid $border-color;
}

.datepicker-legend {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
}

.datepicker-legend-item {
    display: flex;
    align-items: center;
}

.datepicker-legend-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 1.2rem;

    border-radius: $borderradius-sm;

    &.is-success {
        background-color: $color-primary-light;

        @include icon('check', 1rem);

        &::after {
            background-color: $white;
        }
    }

    &.is-danger {
        background-color: $color-danger;

        @include icon('x', 1rem);

        &::after {
            background-color: $white;
        }
    }
}

.datepicker-legend-text {
    margin-left: $spacing-sm;
    font-size: $fontsize-body-sm;
}

.datepicker-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding: $spacing-sm;

    border-bottom: 1px solid $border-color;

    @include mq('sm') {
        padding-left: $spacing-md;
        padding-right: $spacing-md;
    }
}

.datepicker-center {
    margin-left: $spacing-sm;
    margin-right: $spacing-sm;
}

.datepicker-currentdate {
    display: flex;
    align-items: center;
}

.datepicker-currentdate-icon {
    position: relative;
    top: -2px;
    margin-right: $spacing-sm;
}

.datepicker-currentdate-date {
    text-align: center;
}

.datepicker-body {
    padding: $spacing-sm;

    @include mq('sm') {
        padding-top: $spacing-md;
        padding-bottom: $spacing-md;
        padding-left: $spacing-lg;
        padding-right: $spacing-lg;
    }
}

.datepicker-weekdays,
.datepicker-days {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.datepicker-weekdays {
    margin-bottom: $spacing-sm;
    padding-bottom: $spacing-sm;
    border-bottom: 1px dashed $border-color;
}

.datepicker-weekday {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;

    width: $datepicker-column-width;
    height: auto;

    & + & {
        margin-left: $spacing-xs;
    }
}

.datepicker-weekday-day {
    font-size: $fontsize-body-xs;
    color: $color-subsidiary;
}

.datepicker-weekday-date {
    font-size: $fontsize-body-sm;
}

.datepicker-section {
    display: flex;
    flex-flow: column nowrap;

    &:not(:last-child) {
        margin-bottom: $spacing-sm;
        padding-bottom: $spacing-sm;
        border-bottom: 1px dashed $border-color;
    }
}

.datepicker-days-label {
    margin-bottom: $spacing-xs;
    font-size: $fontsize-body-sm;
    color: $color-subsidiary;
}

.datepicker-day {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $datepicker-column-width;

    & + & {
        margin-left: $spacing-sm;
    }
}

.datepicker-button {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;

    width: 3.2rem;
    height: 3.2rem;

    border: 0;
    border-radius: $button-borderradius;
    background-color: transparent;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover {
        background-color: $color-subsidiary-lightest;
    }

    &:active {
        background-color: transparent;
    }
}

.datepicker-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus-visible ~ .datepicker-label {
        @include focus-visible();
    }

    &[type="checkbox"] {
        margin: 0;

        & ~ .datepicker-label {
            @include icon('x');

            &::after {
                background-color: $white;
            }
        }

        &:checked {
            & ~ .datepicker-label {
                text-decoration: none;
                color: $color-text;
                background-color: $color-primary-light;

                &::after {
                    mask-image: icon('check');
                    background-color: $white;
                }
            }

            &:hover ~ .datepicker-label {
                background-color: $color-primary-dark;
            }
        }

        & ~ .datepicker-label {
            background-color: $color-danger;

        }

        &:hover ~ .datepicker-label {
            background-color: $color-danger-dark;
        }

        &:disabled {
            cursor: not-allowed;

            & ~ .datepicker-label {
                background-color: $color-subsidiary-lighter;

                &::after {
                    background-color: $color-subsidiary;
                }
            }

            &:hover ~ .datepicker-label {
                background-color: $color-subsidiary-lighter;
            }
        }
    }
}

.datepicker-label {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 3.2rem;
    height: 3.2rem;

    border-radius: $borderradius-md;
    cursor: pointer;

    transition: background-color 0.1s;

    > .icon {
        color: $white;
    }

    .datepicker-item:hover &,
    .datepicker-item:focus & {
        color: $color-text;
        background-color: $color-primary-light;
    }

    .datepicker-item:active & {
        color: $color-text;
        background-color: transparent;
    }

    @include mq('sm') {
        width: 4rem;
        height: 2.4rem;
    }
}

.datepicker-caption {
    margin-top: $spacing-sm;
    color: $color-text-light;

    > :last-child {
        margin-bottom: 0;
    }
}
