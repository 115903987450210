@use 'settings/global' as *;

/// Quote
blockquote, .explanation {
    display: block;
    margin-bottom: $spacing-md;
    margin-left: $spacing-xl;
    margin-top: 0;
    margin-right: 0;

    color: $color-primary;
    font-family: $fontfamily-body;
    font-weight: 500;
    font-style: italic;
    font-size: $fontsize-heading-sm;
    line-height: $lineheight-body;
    
    &.blockquote-small {
        font-size: $fontsize-body-lg;
    }

    h1, h2, h3, h4, h5, h6 {
        display: inline;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-style: inherit;
        line-height: inherit;  
        text-transform: none;

        .contrast-element & {
            color: inherit;
        }
    }
    
    p {
        display: inline;
        line-height: inherit;
    }

    &::before, &::after {
        display: inline-block;
        line-height: 1;
        font-style: normal;
        position: relative;
        top: 0.15em;
    }

    &::before {
        content: '“';
        font-size: 1.25em;
        line-height: 1;
        // margin-right: -0.4rem;
    }

    &::after {
        content: '”';
        font-size: 1.25em;
        line-height: 1;
        // margin-left: -0.4rem;
    }

    cite {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: $fontsize-body-md;

        &::before {
            content: '- ';
        }
    }
}