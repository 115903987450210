@use 'settings/global' as *;
@use 'tools' as *;

.credits-container {
    // padding-top: $spacing-md;
    // padding-bottom: $spacing-md;
    background-color: $white;
    
    @media print {
        display: none;
    }
}

.credits {  
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    // padding: 0 $spacing-md;

    @include max-width-breakpoints();
    
    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
}

.credits-start,
.credits-end {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    display: flex;
    align-items: center;

    // @include mq('lg') {
    //     margin-top: 0;
    //     margin-bottom: 0;
    // }
}

.creditsnav {
    @include mq('mobilenav') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        // justify-content: center;
    }
}

.creditsnav-list {
    display: block;
    list-style: none;
    margin: 0;

    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.creditsnav-item {
    display: flex;
    align-items: flex-start;

    & + & {
        @include mq(0px, 'md') {
            margin-top: $spacing-md;
        }

        @include mq('md') {
            &::before {
                content: "/";
                color: $color-text;
                margin-right: $spacing-sm;
                margin-left: $spacing-sm;
                // display: inline-block;
            }
        }
    }
}

.creditsnav-link {
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    white-space: nowrap;
    text-decoration: none;
    color: $color-text;

    &:hover, &:focus {
        color: $color-primary;
        text-decoration: underline;
    }

    &:active {
        color: $color-primary;
        text-decoration: none;
    }
}

button.creditsnav-link {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;
    height: auto;
    background-color: transparent;
    border-radius: 0;
    border: 0;
}