@use 'settings/global' as *;
@use 'components/core/button' as *;

$form-background-color:         $white;
$form-input-background:         $white;
$form-padding:                  $spacing-md !default;

$form-height:                   $button-height !default;
$form-row-margin:               $spacing-md !default;

$form-label-fontfamily:         $fontfamily-body !default;
$form-label-fontsize:           $fontsize-body-md !default;
$form-label-fontweight:         500 !default;
$form-label-color:              $color-text;
$form-label-spacing:            $spacing-xs * 3 !default;

$form-padding-x:                $spacing-sm !default;

$form-border:                   0.1rem solid;
$form-border-radius:            $button-borderradius !default;
$form-border-color:             $color-subsidiary-lightest;
$form-focus-color:              $color-primary-darkest;
$form-border-focus-outline:     0.2rem solid $form-focus-color;

$form-error-fontsize:           $fontsize-body-sm !default;
$form-error-fontweight:         $fontweight-body;

$form-input-selectors: 
    '[type="color"]',
    '[type="date"]',
    '[type="datetime"]',
    '[type="datetime-local"]',
    '[type="email"]',
    '[type="month"]',
    '[type="number"]',
    '[type="password"]',
    '[type="search"]',
    '[type="tel"]',
    '[type="text"]',
    '[type="time"]',
    '[type="url"]',
    '[type="week"]';
