@use 'settings/global' as *;
@use 'tools' as *;

.ordersteps {
    margin: 0;
    list-style: none;

    > * {
        margin-bottom: $spacing-xl;

        @include mq('lg') {
            margin-bottom: $spacing-2xl;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ordersteps-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.ordersteps-headline {
    font-size: $fontsize-heading-md;
    font-weight: 700;
}

.ordersteps-media {
    flex: 0 0 auto;
    order: -1;
    margin-right: $spacing-md;
    width: 6.4rem;
    height: 6.4rem;

    @include mq('md') {
        margin-right: $spacing-xl;
    }
}
