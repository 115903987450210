@use 'settings/global' as *;
@use 'tools' as *;

@mixin containerBackground($remove-margins: true) {
    @if($remove-margins) {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;

    @include mq('lg') {
        padding-top: $spacing-2xl;
        padding-bottom: $spacing-2xl;
    }

    &.container--small {
        padding-top: $spacing-md;
        padding-bottom: $spacing-md;

        @include mq('lg') {
            padding-top: $spacing-md;
            padding-bottom: $spacing-md;
        }
    }

    &.container--large {
        @include mq('lg') {
            padding-top: $spacing-3xl;
            padding-bottom: $spacing-3xl;
        }
    }
}

.container {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;

    @include mq('lg') {
        margin-top: $spacing-2xl;
        margin-bottom: $spacing-2xl;
    }

    &.container--small {
        margin-top: $spacing-md; // if needed depends on how large container is $spacing-xl then also adjust here
        margin-bottom: $spacing-md;

        @include mq('lg') {
            margin-top: $spacing-lg;
            margin-bottom: $spacing-lg;
        }
    }

    &.container--large {
        @include mq('lg') {
            margin-top: $spacing-3xl;
            margin-bottom: $spacing-3xl;
        }
    }
}

.container--primary {
    background-color: $color-primary;

    @include containerBackground();
}

.container--secondary {
    background-color: $color-secondary;

    @include containerBackground();
}

// .container--light {
//     background-color: $color-subsidiary-lightest;

//     @include containerBackground();
// }

// .container--dark {
//     background-color: $color-subsidiary-darker;

//     @include containerBackground();
// }

// .container--darkest {
//     background-color: $color-subsidiary-darkest;

//     @include containerBackground();
// }

// .container--offwhite {
//     background-color: $offwhite;
    
//     @include containerBackground();
// }
