@use 'settings/global' as *;
@use 'tools' as *;

/// Headings
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    margin-top: 0;
    margin-bottom: $spacing-md;
    padding: 0;

    font-family: $fontfamily-heading;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    line-height: 1.3;
    color: $color-text;
    overflow-wrap: break-word;

    // anchors in headings should remain like headings, not anchors
    a {
        display: inline-block;
        color: inherit;
        text-decoration: inherit;
        font-weight: inherit;

        &:hover, &:focus, &:active {
            color: inherit;
            text-decoration: none;
            border-bottom: 0;
        }
    }

    img {
        vertical-align: baseline;
    }

    .contrast-element &:not([class]) {
        color: $white;
    }
}


h1, .h1 {
    font-size: $fontsize-heading-2xl;

    @include mq('md') {
        font-size: $fontsize-heading-3xl;
    }
}

h2, .h2, .fui-heading-h2 {
    font-size: $fontsize-heading-2xl;
}

h3, .h3, .fui-heading-h3 {
    font-size: $fontsize-heading-xl;
}

h4, .h4, .fui-heading-h4 {
    font-size: $fontsize-heading-lg;
}

h5, .h5, .fui-heading-h5 {
    font-size: $fontsize-heading-md;
}

h6, .h6, .fui-heading-h6 {
    font-size: $fontsize-heading-sm;
}
