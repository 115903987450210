@use 'settings/global' as *;
@use 'tools' as *;

.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    // overflow-x: hidden;
    // overflow-y: initial;
    // max-width: 168rem;
    // background-color: $color-primary-lightest;
    // margin: 0 auto;
}

.site-header {
    flex: none;
    background-color: $white;
    position: relative;
    z-index: 99;
}

.site-main {
    position: relative;
    flex: 1 0 auto;
    // padding-bottom: $spacing-md;
}

.site-footer {
    flex: none;
    // border-top: 1px solid $color-subsidiary-dark;
}
