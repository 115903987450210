@use 'settings/global' as *;
@use 'tools' as *;

.login-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-secondary-darker;

    @supports (min-height: 100svh) {
        min-height: 100svh;
    }

    @supports not (min-height: 100svh) {
        min-height: 100vh;
    }
}

.login {
    margin: $spacing-md;
    padding: $spacing-md;
    width: 50rem;
    border-radius: $borderradius-md;
    background-color: $white;

    @include mq('md') {
        padding: $spacing-xl;
    }
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.login-body {
    order: 2;
}

.login-headline {
    font-size: $fontsize-heading-md;

    @include mq('md') {
        font-size: $fontsize-heading-lg;
    }
}

.login-media {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-xl;
    order: 1;
}

.login-image {
    width: 15rem;

    @include mq('md') {
        width: 20rem;
    }
}

.login-menulist-item {
    & + & {
        margin-top: $spacing-sm;
    }
}

.login-form {
    margin-bottom: $spacing-md;

    &:last-child {
        margin-bottom: 0;
    }
}