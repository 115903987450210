@use 'settings/global' as *;
@use 'tools' as *;

.mobilenav {
    position: relative;
    flex: 0 1 auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-left: $spacing-md;
    // margin-right: -$spacing-md;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     z-index: 0;
    //     top: 0;
    //     bottom: 0;
    //     left: -100vw;
    //     right: -100vw;
    //     border: 1px solid $color-subsidiary-dark;
    // }

    @include mq('mobilenav') {
        display: none;
    }
}

.mobilenav-item {
    flex: 0 1 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & + & {
        margin-left: $spacing-sm;
    }
}

.mobilenav-logo {
    max-height: 22px;
}

.mobilenav-button,
.mobilenav-toggle {
    position: relative;
    z-index: 2;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;

    margin: 0;
    padding: 0;

    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    font-family: $fontfamily-body;
    font-weight: 600;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    line-height: 1;
    color: $color-primary-dark;

    border: none;
    border-radius: $borderradius-sm;
    // background: transparent;
    background-color: transparent;
    transition: background-color 0.1s, color 0.1s;

    &:hover {
        color: $color-primary-light;
        background-color: transparent;
        text-decoration: none;
        // background-color: $color-primary-light;

        .mobilenav-icon,
        .mobilenav-hamburger {
            color: currentColor;
        }
    }

    // &:active {
    // color: $color-text;
    // background-color: $color-primary-light;
    // background-color: transparent;
    // }

    @media print {
        display: none;
    }
}

.mobilenav-buttontext {
    @include visually-hidden();
}

.mobilenav-action {
    display: none;

    @include mq(540px) {
        display: block;
    }
}

.mobilenav-icon {
    font-size: $fontsize-heading-xl;
    color: $color-primary;
    margin-right: 0.2rem;
}

.mobilenav-toggle-label {
    flex: 0 1 auto;
    display: inline-block;
    margin-right: $spacing-sm;

    // @include mq(0px, 520) {
    //     @include visually-hidden();
    // }

    .has-open-navigation & {
        display: none;
    }

    &.is-opened {
        display: none;

        .has-open-navigation & {
            display: inline-block;
        }
    }
}

.mobilenav-hamburger {
    // flex: 0 1 auto;
    // margin-right: 0.6rem;
    color: $color-primary-dark;
}