@use 'settings/global' as *;
@use 'tools' as *;

.order {
    > :last-child {
        margin-bottom: 0;
    }
}

.order-section {
    & + & {
        margin-top: $spacing-xl;
    }

    @include mq('sm') {
        &.order-section-columns {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    // @include mq('lg') {
    // & + & {
    //     margin-top: $spacing-2xl;
    //     // }
    // }

    > :last-child {
        margin-bottom: 0;
    }
}

.order-section-column {
    @include mq(0px, 'sm') {
        & + & {
            margin-top: $spacing-xl;
        }
    }

    @include mq('sm') {
        width: 50%;
    }
}

.order-lineitem {
    & + & {
        margin-top: $spacing-sm;
    }
}

.order-panel {
    margin-bottom: $spacing-md;
}
