@use 'settings/global' as *;
@use 'tools' as *;
@use 'components/core/button' as *;

.topnav {
    position: relative;
}

.topnav-toggle {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;

    border: 0;
    background-color: transparent;
    color: $white;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    font-style: normal;
    line-height: $lineheight-body;

    text-align: center;
    text-decoration: none;
    text-transform: none;
    text-shadow: none;
}

.topsubnav {
    display: block;
    margin: 0;
    margin-top: 0.3em;
    padding: $spacing-xs;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;

    visibility: hidden;
    opacity: 0;

    list-style: none;

    text-align: left;
    border: 1px solid $color-primary;
    border-radius: $borderradius-md;
    background-color: $white;

    box-shadow: 0 2px 6px 0 rgba($color-subsidiary-darkest, 0.8), 0 1px 12px 0 rgba($color-subsidiary-darkest, 0.3);
    min-width: 100%;
    transition: opacity 0.1s;

    @include mq(0px, 'mobilenav') {
        &.topsubnav-left {
            left: auto;
            right: 0;
        }
    }

    @include mq('mobilenav') {
        left: auto;
        right: 0;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
    }
}

.topsubnav-item {
    position: relative;
    display: block;
    margin-bottom: $spacing-sm;
    padding: 0;
    white-space: nowrap;

    &:last-child {
        margin-bottom: 0;
    }
}

.topsubnav-link,
.topsubnav-button {
    display: block;
    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    text-align: left;
    text-decoration: none;
    color: $color-text;

    padding: $spacing-sm;
    border-radius: $borderradius-md;

    transition: color 0.1s, background-color 0.1s;

    &:hover, &:focus {
        text-decoration: none;
        color: $white;
        background-color: $color-primary-light;
    }

    &:active {
        text-decoration: none;
        color: $color-text;
        background-color: rgba($color-primary-dark, 0.6);
    }
}

button.topsubnav-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 100%;
    border: 0;
    background-color: transparent;
    overflow: visible;
    cursor: pointer;
}

.topsubnav-buttontext {
    margin-left: $spacing-sm;

    @include mq(0px, 'md') {
        .topbanner & {
            @include visually-hidden();
        }
    }
}
