@use 'settings/global' as *;
@use 'tools' as *;

.deliveryslot-container {
    margin-bottom: $spacing-md;
    background-color: $color-primary-lightest;

    border: 1px solid $border-color;
    border-radius: $borderradius-md;
}

.deliveryslot {
    padding: $spacing-md;
}

.deliveryslot-header {
    margin-bottom: $spacing-md;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deliveryslot-header-headline {
    margin-bottom: 0;
}

.deliveryslot-row {
    margin-bottom: $spacing-md;
    padding-bottom: $spacing-md;

    border-bottom: 1px solid $border-color;
}

.deliveryslot-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    > * + * {
        padding-left: $spacing-md;
    }

    @include mq('lg') {
        align-items: center;
    }
}

.deliveryslot-item-media {
    padding: $spacing-xs;
    flex: 0 0 auto;
    width: 4rem;

    border-radius: $borderradius-md;
    border: 1px solid $border-color;
}

.deliveryslot-item-image {
    width: 100%;

    // @TODO Tristan fix this when image gets cropped correctly from cms.
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    // End TODO
}

.deliveryslot-item-body {
    @include mq('md') {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        > * + * {
            padding-left: $spacing-md;
        }
    }

    @include mq('lg') {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.deliveryslot-description {
    color: $color-text-light;

    @include mq('md') {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.deliveryslot-item-headline {
    margin-bottom: $spacing-2xs;
    font-size: $fontsize-body-sm;
}

.deliveryslot-item-amount {
    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > * + * {
            padding-left: $spacing-md;
        }
    }
}

.deliveryslot-item-integer {
    @include mq('sm') {
        width: 12rem;
    }
}
