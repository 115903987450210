@use "sass:color";
@use 'settings/global' as *;
@use 'tools' as *;

.panel {
    &:not(.panel--borderless) {
        padding: $spacing-lg;
        border: 1px solid $border-color;
        border-radius: $borderradius-md;
        background-color: $color-primary-lightest;
    }

    > :last-child {
        margin-bottom: 0;
    }

    &.panel--subsidiary {
        background-color: color.scale($color-subsidiary-lightest, $lightness: 80%);
    }
}

.panel-headline {
    margin-bottom: $spacing-sm;

    .panel--subsidiary & {
        color: $color-subsidiary;
    }
}

.panel-body {
    > :last-child {
        margin-bottom: 0;
    }
}

.panel-row {
    margin-bottom: $spacing-md;
}

.panel-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.panel-text {
    width: 55%;

    &.is-discount {
        color: $color-success;
    }
}

.panel-amount {
    width: 45%;

    &.is-discount {
        color: $color-success;
    }
}

.panel-lineitem {
    margin-top: $spacing-md;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.panel-lineitem-start,
.panel-lineitem-end {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.panel-lineitem-start {
    flex: 1 1 auto;
}

.panel-lineitem-end {
    margin-left: auto;
    padding-left: $spacing-md;
}

.panel-lineitem-media {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    margin-right: $spacing-md;

    width: 4rem;
    height: 4rem;
    border: 1px solid $border-color;
    border-radius: $borderradius-md;
}

.panel-lineitem-headline {
    margin-bottom: 0;

    .panel--subsidiary & {
        color: $color-subsidiary;
    }
}
