@use 'settings/global' as *;
@use 'tools' as *;

/// Anchor
@mixin anchor() {
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    color: $color-primary;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.1em;
    border-radius: 0;

    transition: color 0.1s ease-out;

    &:not([class]) {
        outline-offset: 0;
    }

    &:hover, &:focus {
        color: $color-primary-dark;
        text-decoration: none;
    }

    &:active {
        color: $color-primary-darker;
        text-decoration: underline;
        text-decoration-thickness: 1px;
    }

    &:focus-visible {
        @include focus-visible();
    }

    &:visited {}
}

a, .a {
    @include anchor();
}

button.a {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.contrast-element a:not([class]) {
    color: $white;

    &:hover, &:focus {
        color: $color-primary-lightest;
    }

    &:active {
        color: $color-primary-lighter;
    }

    &:focus-visible {
        @include focus-visible();
    }
}