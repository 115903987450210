@use 'settings/global' as *;
@use 'tools' as *;

.breadcrumbs-container {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;

    @include mq('lg') {
        margin-top: 0;
    }
}

.breadcrumbs {
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @include max-width-breakpoints();
}

.breadcrumbs-list {
    list-style: none;
    margin: 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.breadcrumbs-item {
    display: inline-flex;
    align-items: center;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
    margin-left: 0rem;
    margin-right: 0rem;

    .icon {
        margin-left: $spacing-xs;
        margin-right: $spacing-xs;
    }

    @include mq(0px, 'md') {

        &:not(:nth-last-child(2)),
        .icon {
            display: none;
        }
    }
}

.breadcrumbs-link {
    display: inline-flex;
    align-items: center;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-sm;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    color: $color-primary;

    transition: color 0.1s, background-color 0.1s;

    @include mq(0px, 'md') {
        line-height: 2.1rem;
        margin-left: -0.6rem;

        &::before {
            content: "";
            // position: relative;
            // top: 1px;
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            mask-image: icon('arrow-narrow-right');
            mask-repeat: no-repeat;
            mask-size: 100%;
            background-color: $color-subsidiary-lighter;
            margin-right: $spacing-xs;

            transform: rotate(-180deg);
        }
    }
}

a.breadcrumbs-link {
    &:hover, &:focus {
        color: $color-primary;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 0.15em;
    }

    &:active {
        text-decoration: none;
    }

    .breadcrumbs-item:last-child:not(:first-child) & {
        font-weight: 400;
        color: $color-text-light;

        &:hover, &:focus {
            color: $color-primary-dark;
            text-decoration: underline;
        }

        &:active {
            color: $color-primary;
            text-decoration: none;
        }
    }
}
