@use 'settings/global' as *;
@use 'tools' as *;

.contactinfo {
    > * {
        margin-bottom: $spacing-md;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.contactinfo-item {
    margin-bottom: $spacing-sm;

    &:last-child {
        margin-bottom: 0;
    }
}

.contactinfo-link {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    // font-size: $fontsize-body-md;
    // font-weight: $fontweight-body;
    // text-align: left;
    // text-decoration: none;
    // color: $color-primary;

    // &:hover, &:focus {
    // text-decoration: underline;
    //         border-bottom: 1px solid;
    //         border-color: $color-primary-dark;
    // }

    // &:active {
    //         border-bottom: 1px solid;
    //         border-color: transparent;
    //     }
    // }

    .contrast-element & {
        background-color: transparent;
        color: $white;

        &:hover, &:focus {
            color: $color-primary;
        }

        &:active {
            color: $color-primary;
        }
    }
}