@use 'settings/global' as *;
@use 'tools' as *;

.footer-container {
    position: relative;
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;

    background-color: $color-secondary-dark;

    @include mq('mobilenav') {
        margin-top: 0;
        padding-top: $spacing-2xl;
        padding-bottom: $spacing-2xl;
    }
}

.footer {
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    
    @include max-width-breakpoints();
}

.footer-logo {
    display: block;
    // margin-left: -5px;
    margin-right: $spacing-md;
    margin-bottom: $spacing-lg;
    width: 25rem;
}


.footersections {
    @include mq('lg') {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 3.2rem;
        width: 100%;
        text-align: left;
        // margin-top: 6.4rem;
        // margin-bottom: 6.4rem;

        // grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    }

    // display: flex;
    // flex-flow: column nowrap;

    // @include mq('sm') {
    //     display: flex;
    //     flex-flow: row wrap;
    // }

    // // @include mq('mobilenav') {
    // //     flex-flow: row nowrap;
    // // }

    // & + & {
    //     padding-top: $spacing-lg;
    // }
}


.footersection {
    @include mq(0px, 'lg') {
        margin-top: $spacing-2xl;
        margin-bottom: $spacing-2xl;
    }
}

// .footer-section {
// padding: $spacing-md 0;

// @include mq('sm') {
//     flex: 1 1 50%;
// }

// @include mq('lg') {
//     flex: 1 1 33.3334%;
// }

// &:first-child {
//     @include mq(0px, 'lg') {
//         flex: 1 1 100%;
//     }
// }

// &.footer-section-media {
//     @include mq(0px, 'lg') {
//         flex: 1 1 100%;
//     }
// }
// }

.footersection-headline {
    font-family: $fontfamily-heading;
    font-size: $fontsize-heading-md;
    color: $white;
    margin-bottom: $spacing-md;
}

.footernav-list {
    list-style: none;
    margin-left: 0;
    margin-bottom: $spacing-md;
}

.footernav-item {
    & + & {
        margin-top: $spacing-sm;
    }

    @include mq('md') {
        text-align: left;
    }
}

.footernav-link {
    display: block;
    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: 500;
    color: $white;
    text-decoration: none;

    &:hover, &:focus {
        color: $offwhite;
        text-decoration: underline;
    }

    &:active {
        color: transparent;
        text-decoration: none;
    }
}