@use 'settings/global' as *;
@use 'tools' as *;

// .product-information {
//     margin-bottom: $spacing-xl;
//     padding: $spacing-lg;
//     border-radius: $borderradius-md;
//     background-color: $color-primary-lightest;
//     // color: $white;

//     :last-child {
//         margin-bottom: 0;
//     }

//     @include mq('lg') {
//         margin-bottom: 0;
//     }
// }

.product-form {
    padding: $spacing-md;

    background-color: $color-primary-lightest;
    border-radius: $borderradius-md;
    border: 1px solid $color-primary-lighter;

    > :last-child {
        margin-bottom: 0;
    }
}


.product-usps {
    margin-left: $spacing-xl;

    opacity: 0;
    height: 0;
    visibility: hidden;

    transition: opacity 0.1s height 0.1s;

    .product-variant.is-checked & {
        opacity: 1;
        height: auto;
        visibility: visible;
    }
}