@use 'settings/global' as *;

/// Table
table {
    max-width: 100%;
    margin-bottom: $spacing-md;
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}

thead {
    background-color: $color-primary-lightest;
    color: $color-text;
}

td,
th {
    vertical-align: top;
    line-height: $lineheight-body;
    padding: $spacing-sm;
    border-top: 1px solid $color-subsidiary-lighter;
    border-bottom: 1px solid $color-subsidiary-lighter;
    font-size: $fontsize-body-md;
}

th {
    font-weight: 600;
    text-align: left;
}

figure.table {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
