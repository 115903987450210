@use 'settings/global' as *;
@use 'tools' as *;

.subnav-toggle {
    position: absolute;
    top: 0;
    right: $spacing-sm;
    z-index: 1;

    padding: 0;
    height: 3rem;
    width: 3rem;

    font-size: 3rem;
    text-align: center;
    line-height: 3rem;
    color: $color-text;

    cursor: pointer;
    border: 0;
    background: none;
    transition: color 0.1s, background-color 0.1s;

    @include mq('mobilenav') {
        display: none;
    }

    &:hover, &:focus {
        color: $color-text;
        background-color: transparent;
    }

    &:active {
        color: $color-text;
        background-color: transparent;
    }

    > .icon {
        transition: transform 0.2s ease-in-out;
    }

    // .subnav-item > & {
    //     height: 4.2rem;
    //     line-height: 4.2rem;
    //     width: 4.2rem;

    //     &:hover {
    //         background-color: rgba($color-secondary-light, 0.1);
    //     }
    // }

    .is-opened > & {
        > .icon {
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
        }
    }
}

.subnav {
    list-style: none;
    margin: 0;

    @include mq(0px, 'mobilenav') {
        display: none;
        margin-top: 0.2em;
        margin-left: $spacing-md;
        margin-right: $spacing-md;
        //padding-top: $spacing-md;
        //padding-bottom: $spacing-md;
        background-color: $white;
        border: 2px solid $color-primary-light;

        // & .subnav {
        //     border-top: 0;
        //     border-bottom: 0;
        // }

        &[aria-hidden="false"] {
            display: block;
        }
    }

    @include mq('mobilenav') {
        display: block;
        margin-top: 0.3em;
        padding: $spacing-xs;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 99;

        visibility: hidden;
        opacity: 0;

        text-align: left;
        border: 1px solid $color-primary;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba($color-subsidiary-darkest, 0.8), 0 1px 12px 0 rgba($color-subsidiary-darkest, 0.3);
        min-width: 100%;
        transition: opacity 0.1s;

        //show on hover, spacing between parent fix
        &::before {
            content: '';
            display: block;
            height: 0.5em;
            left: 0;
            right: 0;
            position: absolute;
            top: -0.5em;
        }

        // &.is-right {
        //     left: auto;
        //     right: 0;
        // }

        &[aria-hidden="false"] {
            visibility: visible;
            opacity: 1;
        }

        // .subnav {
        //     left: 100%;
        //     right: auto;
        //     top: 0;
        //     margin-top: 0;
        // }

        // &.subnav--left .subnav {
        //     left: auto;
        //     right: 100%;
        // }

        .mainnav-item:last-child & .subnav {
            right: 100%;
            left: auto;
        }
    }
}

.subnav-item {
    position: relative;
    // float: none;
    display: block;
    padding: 0;
    // white-space: nowrap;

    @include mq('mobilenav') {
        padding: 0;
        white-space: nowrap;

        // & + & {
        //     border-top: 1px solid $color-subsidiary-dark;
        // }

        &.is-active {
            border-bottom: 2px solid $color-primary;
        }
    }
}

.subnav-link {
    position: relative;
    display: block;
    overflow: hidden;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: $fontweight-body;
    text-align: left;
    text-decoration: none;
    color: $color-text;
    transition: color 0.1s, background-color 0.1s;

    &:hover, &:focus {
        color: $color-text;
        background-color: $color-secondary-lightest;
        text-decoration: none;
    }

    &:active {
        color: $color-text;
        background-color: transparent;
    }

    @include mq(0px, 'mobilenav') {
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        // padding-left: $spacing-md + 4.2rem;

        // &::before {
        //     content: '-';
        //     display: inline-block;
        //     width: $spacing-md;
        //     text-align: right;
        //     color: $color-primary;
        // }

        &:hover, &:focus {
            text-decoration: none;
            background-color: rgba($color-primary-light, 0.4);
            color: $color-text;
        }

        &:active {
            text-decoration: none;
            background-color: rgba($color-primary-light, 0.4);
            color: $color-text;
        }

        .subnav-item.is-active > & {
            color: $color-primary;

            &:hover, &:focus {
                color: $color-primary-dark;
            }

            &:active {
                color: $color-primary-darkest;
            }
        }

        // .subnav .subnav & {
        //     padding-right: $spacing-xl;
        // }
    }

    // .subnav-item.is-active > & {
    //     color: $color-secondary;

    //     &:hover, &:focus {
    //         color: $color-text;
    //     }
    // }

    @include mq('mobilenav') {
        display: block;

        padding-top: $spacing-md;
        padding-bottom: $spacing-md;
        padding-left: $spacing-md;
        padding-right: $spacing-md;

        &:hover, &:focus {
            text-decoration: none;
            color: $color-text;
            background-color: $color-primary-light;
            // background-color: rgba($color-subsidiary-dark, 0.5);

            // &::before {
            //     left: 1rem !important;
            // }
        }

        &:active {
            text-decoration: none;
            color: $color-text;
            background-color: rgba($color-primary-dark, 0.6);
        }

        .subnav-item.is-active > & {
            // font-weight: 700;
            color: $color-primary;

            &:hover, &:focus {
                color: $color-primary-dark;
            }

            &:active {
                color: $color-primary-darkest;
            }
        }
    }
}