@use 'settings/global' as *;
@use 'tools' as *;

$gallery-button-width: 4rem;
$gallery-bullet-width: 1rem;

.gallery-wrapper {
    position: relative;
    align-items: center;
}

.gallery-bullet {
    display: inline-block;
    width: $gallery-bullet-width;
    height: $gallery-bullet-width;
    border-radius: 50%;
    background-color: $color-subsidiary-lightest;

    & + & {
        margin-left: $spacing-xs;
    }

    &.is-active {
        background-color: $color-primary;
    }
}

.gallery-controls {
    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 50%;
    left: $spacing-md;
    right: $spacing-md;

    > button + button {
        margin-left: $spacing-md;
    }
}

.gallery-button-prev,
.gallery-button-next {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;

    width: $gallery-button-width;
    height: $gallery-button-width;
    overflow: visible;
    cursor: pointer;
    z-index: 1;

    border: 0;
    border-radius: $borderradius-md;
    background-color: $color-primary;
    color: $white;
    text-decoration: none;

    transition: right 0.1s ease-out, background-color 0.1s ease-out, color 0.1s ease-out;

    &:hover {
        background-color: $color-primary-dark;
        color: $white;
    }

    &:active {
        background-color: $color-primary-darker;
        color: $white;
    }
}

.gallery-thumbs {
    margin-top: $spacing-md;
}

.gallery-image {
    border-radius: $borderradius-md;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:not(.is-thumb) {
        max-height: 32rem;
        object-fit: contain;
        height: 100%;
    }

    &.is-thumb {
        cursor: pointer;

        @supports(aspect-ratio: 1/1) {
            aspect-ratio: 1/1;
            object-fit: cover;
            width: 100%;
        }
    }
}