@use 'settings/global' as *;
@use 'tools' as *;

.autocomplete {
    position: relative;
}

.autocomplete-listbox {
    margin: 0;
    list-style: none;

    max-height: 12em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 999;

    width: 100%;
    max-width: max-content;
    min-width: 100%;

    border-bottom-left-radius: $borderradius-md;
    border-bottom-right-radius: $borderradius-md;

    background-color: $white;
    box-shadow: $boxshadow;

    &:focus-visible {
        @include focus-visible();
    }
}

.autocomplete-listbox-item {
    padding: $spacing-sm;
    background-color: transparent;
    cursor: pointer;

    transition: background-color 0.1s, color 0.1s;

    &:hover, &:focus {
        color: $white;
        background-color: $color-primary;
    }
}

// .autocomplete {
//     position: relative;

//     & [role='listbox'] {
//         max-height: 12em;
//         overflow-y: scroll;
//         -webkit-overflow-scrolling: touch;
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 100%;
//         z-index: 999;
//         max-width: max-content;
//         width: 100%;
//         min-width: 100%;

//         // @apply bg-white shadow-xl;
//         background-color: red;

//     }

//     & [role='option'] {
//         padding-top: $spacing-md;
//         padding-bottom: $spacing-md;
//         padding-left: $spacing-sm;
//         padding-right: $spacing-sm;

//         // &:focus {
//         // @apply shadow-none outline-none;
//         // }
//     }

//     & [role='option'].highlight,
//     & [role='option']:hover,
//     & [role='option'][aria-selected='true']:hover {
//         cursor: pointer;
//         background-color: red;
//         color: blue;

//         // @apply cursor-pointer bg-primary-500 text-primary-contrast;

//         // &::after {
//             // @apply text-primary-contrast;
//         // }
//     }

//     & [role='option'][aria-selected='true'] {
//         // @apply flex items-center justify-between text-gray-500;

//         // @mixin icon check;

//         &::after {
//             // @apply flex-shrink-0 block text-black;
//         }
//     }

//     & .currently-selected-divider {
//         @apply px-2 py-1 italic;
//     }

//     &__select {
//         @apply flex;

//         input {
//             background-color: transparent;
//             border: 0 none;
//         }

//         input:focus {
//             @apply shadow-none outline-none;
//         }

//         &--focused {
//             @apply ring;
//         }
//     }

//     &__placeholder {
//         @apply truncate opacity-25;
//     }

//     &__dropdown-icon {
//         @apply flex items-center px-2;

//         @mixin icon-before chevron-down;
//     }

//     &__selection {
//         display: flex;
//         @apply mb-1 mr-2 overflow-hidden rounded bg-primary-500 text-primary-contrast;

//         .text {
//             @apply px-2;
//         }

//         .close-btn {
//             @apply px-1 border-l border-white cursor-pointer;

//             @mixin icon-before clear;

//             &:hover,
//             &:focus {
//                 @apply bg-primary-700;
//             }
//         }
//     }

//     &__input-wrapper {
//         width: 1px;
//         flex: 1;
//         display: flex;
//         flex-wrap: wrap;
//         @apply p-2;

//         &.has-placeholder {
//             flex-wrap: nowrap;
//         }
//     }
// }
