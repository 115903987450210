@use 'settings/global' as *;

/// Images
img, svg {
    max-width: 100%;
    height: auto;
    border-width: 0;
    vertical-align: middle;
}

// img {
//     color: transparent; // So we don't see alt text while the image loads
// }

img[src$=".svg"] {
    max-height: 100%;
}

// img {
//     object-fit > & {
//         max-width: none;
//     }
// }

svg {
    max-height: 100%;
}

picture {
    display: block;
}

figure {
    display: block;
    margin: 0 0 $spacing-md 0;

    img {
        margin-bottom: $spacing-sm;
    }

    video {
        width: 100%;
    }
}

figcaption {}