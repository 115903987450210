@use 'settings/global' as *;
@use 'tools' as *;

/// Contrast element
.contrast-element {
    color: $white;

    h1, h2, h3, h4, h5, h6 {
        &:not([class]) {
            color: $white;
        }
    }

    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $white;
    }

    a:not([class]) {
        color: $white;

        &:hover, &:focus {
            color: $color-primary;
            text-decoration: underline;
        }

        &:active {
            color: $color-primary;
            text-decoration: none;
        }
    }
}

.contrast-container {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    padding: $spacing-md;
    background-color: $color-primary-darkest;
}