@use 'settings/global' as *;

/// Paragraph
p {
    margin-top: 0;
    margin-bottom: $spacing-md;

    &:empty {
        display: none;
    }

    // &:last-of-type {
    //     margin-bottom: 0
    // }
}