@use 'settings/global' as *;
@use 'settings/form-config' as *;
@use 'components/core/button' as *;
@use 'tools' as *;

input[type="file"]::file-selector-button {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;

    margin-right: $spacing-md;
    padding-top: $button-padding-y;
    padding-bottom: $button-padding-y;
    padding-left: $button-padding-x;
    padding-right: $button-padding-x;

    border-radius: $button-borderradius;
    border: $form-border $form-border-color;
    background-color: $white;
    color: $color-text;

    font-family: $button-fontfamily;
    font-size: $button-fontsize;
    font-weight: $button-fontweight;
    font-style: normal;
    line-height: $button-lineheight;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    text-shadow: none;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover {
        background-color: $color-primary-light;
        color: $color-text;
    }

    &:active {
        background-color: transparent;
        color: $color-text;
    }

    &:focus-visible {
        @include focus-visible();
        // outline-color: $color-primary;
        outline-offset: 1px;
    }
}