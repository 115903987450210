@use 'settings/global' as *;
@use 'settings/form-config' as *;
@use 'tools' as *;

.checkbox {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    vertical-align: top;
    cursor: pointer;

    &:has(> [type="checkbox"]:disabled),
    &:has(> [type="radio"]:disabled) {
        cursor: default;
    }

    > [type="radio"],
    > [type="checkbox"] {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:checked ~ .checkbox-indicator {
            border-color: $color-primary;
            background-image: none;

            &::after {
                opacity: 1;
                background-color: $color-primary;
                transform: scale(1);
            }
        }

        &:focus ~ .checkbox-indicator {
            border-color: $color-primary;
            outline: none;
        }

        &:focus-visible ~ .checkbox-indicator {
            @include focus-visible();
        }

        &:disabled ~ .checkbox-indicator {
            opacity: 0.6;
        }

        &:disabled ~ .checkbox-indicator {
            border: $form-border $form-border-color;
            background-color: $color-primary-lightest;
            color: $color-text;
        }

        &:disabled ~ .checkbox-label {
            color: $color-text-light;
        }
    }
}

.checkbox-label {
    .checkbox-indicator + & {
        margin-left: $spacing-sm;
    }
}

.checkbox-indicator {
    position: relative;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 1;

    overflow: hidden;
    pointer-events: none;

    border: $form-border $form-border-color;
    border-radius: $form-border-radius;
    background-color: $form-input-background;

    transition: border 0.1s;

    &::after {
        content: "";
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        text-align: center;
        background-color: $white;

        opacity: 0;
        transform: scale(0);

        transition: opacity 0.1s;
    }

    [type="radio"] ~ & {
        border-radius: 50%;

        &:after {
            border-radius: 50%;
        }
    }

    [type="checkbox"] ~ & {
        &::after {
            content: "";
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            font-size: $fontsize-body-lg;
            color: $color-primary;
            mask-image: icon("checkmark");
            mask-repeat: no-repeat;
            mask-size: 100% auto;
            background-color: $white;
        }
    }
}
