@use 'settings/global' as *;
@use 'tools' as *;

$icon-sizes: ('smallest': 1rem,
        'small': 1.4rem,
        'large': 2.4rem) !default;

.icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    pointer-events: none;
    flex-shrink: 0;

    &:hover, &:focus, &:active {
        color: inherit;
    }

    @each $size, $value in $icon-sizes {
        &.icon--#{$size} {
            width: $value;
            height: $value;
        }
    }

    &.icon-spacing-left {
        margin-left: $spacing-sm;
    }

    &.icon-spacing-right {
        margin-right: $spacing-sm;
    }

    .contrast-element & {
        color: $white;

        &:hover, &:focus {
            color: $offwhite;
        }
    }
}

.icon--light {
    color: $color-subsidiary-lighter;
}
