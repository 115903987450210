@use "sass:color";
@use 'tools/functions' as *;

/// BASICS
// assets paths (see functions assets helper)
$assets-image-path:  '/frontend/images/site';
$assets-icon-path:   '/static/frontend/icons/css';
// $assets-font-path: '../fonts';

// defaults
$spacing-2xs:   0.2rem;
$spacing-xs:    0.4rem;
$spacing-sm:    0.8rem;
$spacing-md:    1.6rem;
$spacing-lg:    2.4rem;
$spacing-xl:    3.2rem;
$spacing-2xl:   6.4rem;
$spacing-3xl:   12.8rem;

/// Layout
$screen-xs:     48.0rem;  
$screen-sm:     66.0rem;
$screen-md:     80.0rem;
$screen-lg:     98.0rem;
$screen-xl:     120.0rem;
$screen-2xl:    144.0rem;
$screen-3xl:    160.0rem; 

$grid-columns:  12;

/// Breakpoints
$breakpoints: (
    'xs': remToPx($screen-xs),
    'sm': remToPx($screen-sm),
    'md': remToPx($screen-md),
    'lg': remToPx($screen-lg),   
    'xl': remToPx($screen-xl),
    '2xl': remToPx($screen-2xl),
    '3xl': remToPx($screen-3xl),
    'mobilenav': remToPx($screen-lg)
);

$rowcol-breakpoints: (
    'xs': remToPx($screen-xs),
    'sm': remToPx($screen-sm),
    'md': remToPx($screen-md),
    'lg': remToPx($screen-lg),
    'xl': remToPx($screen-xl)
);

$rowcol-columns:  12;

$grid-breakpoints: (
    '0': 0,
    'xs': remToPx($screen-xs),
    'sm': remToPx($screen-sm),
    'md': remToPx($screen-md),
    'lg': remToPx($screen-lg),
    'xl': remToPx($screen-xl),
    '2xl': remToPx($screen-2xl)
);

$grid-columns:  (2, 3, 4);

/// COLORS
$offwhite:          #ebebeb; //#EAF3FB
$white:             #FFFFFF;
$gray-lightest:     #eaeaea;
$gray-lighter:      #DDDDDD;
$gray-light:        #ababab;
$gray:              #8b8b8b;
$gray-dark:         #606060;
$gray-darker:       #3c3c3c;
$gray-darkest:      #292929;
$black:             #000000;

//color names
$color-primary:               #729813;
$color-primary-dark:          #56700E;
$color-primary-darker:        #253107;
$color-primary-darkest:       #0d1102;
$color-primary-light:         #95BB2B;
$color-primary-lighter:       #d1df96;
$color-primary-lightest:      #FBFCF7;

$color-secondary:             #614840;
$color-secondary-dark:        #42322c;
$color-secondary-darker:      #2b201d;
$color-secondary-darkest:     #181210;
$color-secondary-light:       #88726b;
$color-secondary-lighter:     #cfc2bd;
$color-secondary-lightest:    #e6e2e1;

$color-subsidiary:            $gray;
$color-subsidiary-dark:       $gray-dark;
$color-subsidiary-darker:     $gray-darker;
$color-subsidiary-darkest:    $gray-darkest;
$color-subsidiary-light:      $gray-light;
$color-subsidiary-lighter:    $gray-lighter;
$color-subsidiary-lightest:   $gray-lightest;

$color-text:                  $color-primary-darkest;
$color-text-light:            $color-subsidiary-light;
$color-text-lightest:         $color-subsidiary-lightest;

$color-overlay:               rgba($color-primary-dark, 0.6);

$color-success:              $color-primary;
$color-success-dark:         $color-primary-dark;
$color-success-darker:       $color-primary-darker;
$color-success-light:        $color-primary-light;
$color-success-lighter:      $color-primary-lighter;
$color-success-lightest:     $color-primary-lightest;

$color-warning:              #F5A623;
$color-warning-dark:         color.scale($color-warning, $lightness: -30%);
$color-warning-darker:       color.scale($color-warning, $lightness: -50%);
$color-warning-light:        color.scale($color-warning, $lightness: 40%);
$color-warning-lighter:      color.scale($color-warning, $lightness: 60%);
$color-warning-lightest:     #fff2e5;

$color-danger:               #FF6200;
$color-danger-dark:          color.scale($color-danger, $lightness: -30%);
$color-danger-darker:        color.scale($color-danger, $lightness: -50%);
$color-danger-light:         color.scale($color-danger, $lightness: 40%);
$color-danger-lighter:       color.scale($color-danger, $lightness: 60%);
$color-danger-lightest:      #ffe9ea;

//social media
$color-facebook:   #3C5A99;
$color-xtwitter:   #0f1419;
$color-linkedin:   #0077b5;
$color-whatsapp:   #25D366;
$color-youtube:    #FF0000;
$color-pinterest:  #E60023;
$color-dribbble:   #EA4C89;

$gradient-instagram: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
$gradient-tiktok:    linear-gradient(135deg, rgba(37,244,238,1) 0%, rgba(0,0,0,0.85) 50%, rgba(254,44,85,1) 100%);

/// TYPOGRAPHY
$fontfamily-heading:   'Bree Serif', serif;
$fontfamily-body:      'Roboto', sans-serif;

$fontweight-body:      400;
$lineheight-body:      1.5;

$fontsize-body-xs:  1.2rem;
$fontsize-body-sm:  1.4rem;
$fontsize-body-md:  1.6rem;
$fontsize-body-lg:  1.8rem;
$fontsize-body-xl:  2.0rem;

$fontsize-heading-xs:   1.2rem;
$fontsize-heading-sm:   1.4rem;
$fontsize-heading-md:   1.6rem;
$fontsize-heading-lg:   1.8rem;
$fontsize-heading-xl:   2.4rem;
$fontsize-heading-2xl:  3.2rem;
$fontsize-heading-3xl:  4.0rem;

/// DEFAULT STYLES
//header & nav
$mobile-header-height:  12rem;

$border:                1px solid;
$border-color:          $color-subsidiary-lighter;

$borderradius-xs:       0.1rem;
$borderradius-sm:       0.25rem;
$borderradius-md:       0.5rem;
$borderradius-lg:       1rem;

$boxshadow:            rgba(149, 157, 165, 0.2) 0px 8px 24px;
$boxshadow-hover:      0px 4px 40px rgba($black, 0.15);
$boxshadow-active:     0px 4px 40px rgba($black, 0.15);
