@use 'settings/global' as *;
@use 'tools' as *;

.header-container {
    background-color: $white;
}

.header {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.3s;

    @include max-width-breakpoints();

    @include mq(0px, 'mobilenav') {
        height: $mobile-header-height - 4rem;

        .has-open-navigation & {
            box-shadow: 0;
        }
    }

    @include mq('mobilenav') {
        padding: $spacing-md;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;
        min-height: 0;
        background-color: transparent;

        &::before {
            content: "";
            position: absolute;
            bottom: $spacing-md;
            left: $spacing-md;
            right: $spacing-md;
            z-index: 0;

            border-bottom: 1px solid $border-color;
        }
    }
}

.header-logo {
    display: block;
    position: relative;
    z-index: 1;
    // flex: 0 1 auto;
    margin-right: $spacing-md;

    @include mq(0px, 'mobilenav') {
        flex: 0 1 auto;

        > img {
            position: relative;
            top: -0.5rem;
            max-height: $mobile-header-height;
            width: 15rem;
        }
    }

    @include mq('mobilenav') {
        margin-right: $spacing-xl;
        align-self: center;

        > img {
            position: relative;
            top: -$spacing-sm;
            width: 16.5rem;
            transition: width 0.3s;
        }
    }
}

.header-overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
    background-color: $color-overlay;

    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    .has-open-navigation & {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}