@use 'settings/global' as *;
@use 'tools' as *;

.accordion {
    overflow: hidden;
    cursor: pointer;
    transition: height 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

    &:focus-visible {
        @include focus-visible();
    }

    &[open] {
        .icon {
            transition: transform 0.3s ease-in-out;
            transform: rotate(180deg);
        }
    }

    & + & {
        margin-top: $spacing-lg;
    }
}

.accordion-summary {
    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: 700;
    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover, &:focus {
        background-color: $color-primary;
        color: $white;
    }

    &:active {
        background-color: $color-primary-dark;
        color: $white;
    }


    &:has(svg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &::marker,
    &::-webkit-details-marker {
        display: none;
    }

    .accordion[open] & {
        background-color: $color-primary-dark;
        color: $white;

        .accordion-text.is-closed {
            display: none;
        }
    }

    .accordion:not([open]) & {
        .accordion-text.is-opened {
            display: none;
        }
    }

    .accordion--cutoff[open] & {
        display: none;
    }
}

.accordion-text {
    font-size: $fontsize-body-sm;
    color: $color-text;

    .accordion[open] & {
        color: $white;
    }

    .accordion-summary:hover &,
    .accordion-summary:focus &,
    .accordion-summary:active & {
        color: $white;
    }
}

.accordion-summary {
    list-style-type: none;
    padding: $spacing-md;
    border: 0;
    border-radius: $borderradius-md;
    background-color: $color-primary-light;
    outline: none;
    user-select: none;
}

.accordion-panel {
    padding: $spacing-md $spacing-sm 0 $spacing-sm;
    line-height: $lineheight-body;
    color: $color-text-light;
}

// Animated icons 
$animation-duration: 0.2s;
$animation-loading-duration: 0.8s; // only for spin and dots
$animation-timing: ease-in;
$css-icon-border-width: 0.2rem;
$css-icon-border-radius: 50%;
$css-icon-background-color: transparent;
$css-icon-color: currentColor;
$css-icon-dots-background-color: $color-subsidiary;
$css-icon-dots-size: 0.5rem;
$css-icon-icon-size: 1rem;
$css-icon-size: 2.5rem;
// Width need to be the diagonal of the down-arrow side-length (--size): sqrt(2) * --size.
$css-icon-width: calc($css-icon-icon-size * 1.4142135623730950488016887242097);

[data-css-icon] {
    // --animdur: 0.3s;
    // --loading-animdur: 0.8s;
    // --animtf: ease-in;
    // --bdw: 2px;
    // --bdrs: 50%;
    // --bgc: transparent;
    // --c: currentcolor;
    // --dots-bgc: silver;
    // --dots-size: 0.5rem;
    // --icon-size: 1rem;
    // --size: 2.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

[data-css-icon] i {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $css-icon-size;
    height: $css-icon-size;

    border-radius: $css-icon-border-radius;
    background-color: $css-icon-background-color;
    box-sizing: border-box;
    transition: background-color 0.1s ease-in;
    // transition: background-color $animation-duration $animation-timing;
}

[data-css-icon] i::before,
[data-css-icon] i::after {
    transform-origin: 50% 50%;
    transition: transform $animation-duration $animation-timing, background-color 0.1s;
    // transition: all $animation-duration $animation-timing;
}

[data-css-icon*="down"] i::after,
[data-css-icon*="right"] i::after {
    content: "";
    position: relative;
    margin: 0;
    display: inline-block;
    width: $css-icon-icon-size;
    height: $css-icon-icon-size;
    border-style: solid;
    border-color: $css-icon-color;
    background: transparent;
    box-sizing: border-box;
}

[data-css-icon*="down"] i::after {
    top: calc(0px - ($css-icon-icon-size / 4));
    border-width: 0 $css-icon-border-width $css-icon-border-width 0;
    transform: rotate(45deg);
}

[data-css-icon*="right"] i::after {
    top: 0;
    right: calc($css-icon-icon-size / 4);
    border-width: $css-icon-border-width $css-icon-border-width 0 0;
    transform: rotate(45deg);
}

[data-css-icon*="equals"] i::before,
[data-css-icon*="equals"] i::after,
[data-css-icon*="cross"] i::before,
[data-css-icon*="cross"] i::after,
[data-css-icon*="menu"] i,
[data-css-icon*="menu"] i::before,
[data-css-icon*="menu"] i::after,
[data-css-icon*="plus"] i::before,
[data-css-icon*="plus"] i::after {
    content: "";
    position: absolute;
    width: $css-icon-width;
    height: $css-icon-border-width;
    background: $css-icon-color;
}

[data-css-icon*="cross"] i::before,
[data-css-icon*="plus"] i::before {
    transform: rotate(90deg);
}


$css-icon-equals: 4px;

[data-css-icon*="equals"] i {

    &::before {
        transform: translateY(calc(0px - $css-icon-equals));
    }

    &::after {
        transform: translateY($css-icon-equals);
    }
}

[data-css-icon*="dots"],
[data-css-icon*="menu"] {
    height: $css-icon-size;
}

$css-icon-menu: 7px;

[data-css-icon*="menu"] i {
    position: relative;
    right: calc(($css-icon-size - $css-icon-width) / 2);
    border-radius: 0;

    &::before {
        top: calc(0px - $css-icon-menu);
    }

    &::after {
        top: $css-icon-menu;
    }
}

[data-css-icon*="dots"] i,
[data-css-icon*="dots"] i::before,
[data-css-icon*="dots"] i::after {
    content: "";
    display: inline-block;
    width: $css-icon-dots-size;
    height: $css-icon-dots-size;
    border-radius: 50%;
    background-color: $css-icon-color;
    animation: dots $animation-loading-duration infinite alternate;
}

[data-css-icon*="dots"] i {
    position: relative;
    right: calc(($css-icon-size - $css-icon-dots-size) / 4);
    animation-delay: $animation-loading-duration;

    &::before {
        position: absolute;
        left: calc(0px - ($css-icon-dots-size * 1.5));
        animation-delay: calc($animation-loading-duration / 2);
    }

    &::after {
        position: absolute;
        left: calc(0px - ($css-icon-dots-size * 3));
        animation-delay: 0s;
    }
}

[data-css-icon*="spin"] i::after {
    content: "";
    width: $css-icon-icon-size;
    height: $css-icon-icon-size;
    border: $css-icon-border-width solid $css-icon-dots-background-color;
    border-left: $css-icon-border-width solid $css-icon-color;
    border-radius: 50%;
    animation: spin $animation-loading-duration infinite linear;
    transform: translateZ(0);
}

// States
[open] > summary > [data-css-icon*="cross"] i::after {
    transform: rotate(45deg);
}

[open] > summary > [data-css-icon*="cross"] i::before {
    transform: rotate(135deg);
}

[open] > summary > [data-css-icon*="down"] i::after {
    top: $css-icon-border-width;
    transform: rotate(45deg) scale(-1);
}

[open] > summary > [data-css-icon*="right"] i::after {
    right: 0;
    top: calc(0px - (var(--icon-size) / 4));
    transform: rotate(135deg);
}

[open] > summary > [data-css-icon*="plus"] i::after {
    transform: rotate(180deg);
}

[open] > summary > [data-css-icon*="plus"] i::before {
    transform: rotate(-0deg);
}

[open] > summary > [data-css-icon*="equals"] i::after {
    transform: rotate(-45deg);
}

[open] > summary > [data-css-icon*="equals"] i::before {
    transform: rotate(45deg);
}

[open] > summary > [data-css-icon*="menu"] i {
    background-color: transparent;
}

[open] > summary > [data-css-icon*="menu"] i::after {
    transform: translateY(calc(0px - $css-icon-menu)) rotate(-45deg);
}

[open] > summary > [data-css-icon*="menu"] i::before {
    transform: translateY($css-icon-menu) rotate(45deg);
}

// Modifiers
[data-css-icon*="outline"] i {
    border: $css-icon-border-width solid $css-icon-color;
}

[data-css-icon*="fill"] i {
    background-color: $color-primary;
    color: $white;
}

[data-css-icon*="square"] i {
    border-radius: $borderradius-md;
}

// 4 States of summary
/// removed for now

// Animations
@keyframes dots {
    0% {
        background-color: $css-icon-color;
    }

    50%,
    100% {
        background-color: $css-icon-dots-background-color;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}