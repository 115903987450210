@use 'settings/global' as *;
@use 'tools' as *;

.optiongroup {
    &.optiongroup--inline {
        @include mq('sm') {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;

            > .optiongroup-option {
                flex: 0 1 auto;
                margin-right: $spacing-sm;
            }
        }
    }
}

.optiongroup-option {
    & + & {
        margin-top: $spacing-sm;
    }

    // margin-top: $spacing-xs;
    // margin-bottom: $spacing-xs;
}
