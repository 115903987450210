@use 'settings/global' as *;

/// Dividers
hr, .divider {
    border: 0;
    height: 2px;
    background-color: rgba($color-subsidiary-light, 0.4);
    margin-bottom: $spacing-md;
    margin-top: $spacing-md;

    @media print {
        background-color: transparent;
        height: 0;
        border-top: 2px solid $color-primary-lighter;
    }
}