@use 'settings/global' as *;
@use 'tools' as *;

/// Lists
// standard lists
ul, ol {
    margin-top: 0;
    margin-bottom: $spacing-md;
    line-height: $lineheight-body;
    margin-left: 1.25em;
    padding-left: 0;

    ul, ol {
        margin-bottom: 0;
    }

    table &:last-child {
        margin-bottom: 0;
    }
}

ul {
    list-style: disc;

    &:not([class]) {
        list-style: none;

        > li {
            &::before {
                content: "";
                display: inline-block;
                position: relative;
                top: 4px;
                left: -0.5rem;
                width: 2rem;
                height: 2rem;
                margin-left: -2rem;

                mask-image: icon("chevron-right");
                mask-repeat: no-repeat;
                mask-size: 80%;
                mask-position: center center;
                background-color: $color-secondary;
            }
        }

        li + li {
            margin-top: $spacing-xs;
        }
    }

    .contrast-element &:not([class]) > li::before {
        color: $white;
    }
}

ol {
    list-style: decimal;

    &:not([class]) {
        list-style: none;
        counter-reset: li;

        > li {
            counter-increment: li;

            &::before {
                content: counter(li)'.';
                display: inline-block;
                position: relative;
                left: -0.25rem;
                width: 2rem;
                margin-left: -2rem;

                font-family: $fontfamily-body;
                font-weight: 500;
                // font-style: italic;
                color: $color-secondary;
            }
        }

        li + li {
            margin-top: $spacing-md;
        }
    }

    .contrast-element &:not([class]) > li::before {
        color: $white;
    }
}

// definition lists
dl {
    margin-bottom: $spacing-md;
    line-height: $lineheight-body;
}

dt {
    font-weight: 600;
}

dd {
    margin-left: 0;
}