@use 'settings/global' as *;
@use 'tools' as *;

$badge-height: 2.4rem !default;
$badge-lineheight: 1.1 !default;
$badge-fontfamily: $fontfamily-body;
$badge-fontsize: $fontsize-body-sm !default;
$badge-padding-y: $spacing-xs !default;
$badge-padding-x: $spacing-sm !default;
$badge-fontweight: 700;
$badge-borderradius: 2rem !default;
$badge-iconsize: 1.6rem;

.badge {
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    margin: 0;
    width: auto;
    border: 0;
    overflow: visible;
    min-height: $badge-height;

    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
    padding-top: $badge-padding-y;
    padding-bottom: $badge-padding-y;
    padding-left: $badge-padding-x;
    padding-right: $badge-padding-x;
    line-height: $badge-lineheight;
    vertical-align: middle;

    background-color: $color-subsidiary-darkest;
    border-radius: $badge-borderradius;
    color: $white;

    font-family: $fontfamily-body;
    font-size: $badge-fontsize;
    font-weight: $badge-fontweight;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    text-shadow: none;

    @at-root {

        a#{&},
        button#{&} {
            cursor: pointer;

            transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s;

            &:hover, &:focus {
                text-decoration: none;
                color: $white;
                background-color: $color-subsidiary-darker;
            }

            &:active {
                text-decoration: none;
                color: $white;
                background-color: $color-subsidiary-dark;
            }

            &:focus-visible {
                outline-offset: 1px;
                //     outline-color: $color-subsidiary-darker;
            }

            &:disabled {
                background-color: $color-subsidiary-light;
                border-color: $color-subsidiary-light;
                color: $color-text-light;
                cursor: not-allowed;
                opacity: 0.75;

                &:hover, &:focus {
                    background-color: $color-subsidiary-light;
                    border-color: $color-subsidiary-light;
                    color: $color-text-light;
                }
            }
        }
    }
}

.badge.icon-only {
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    min-width: $badge-height;

    > .badge-icon {
        margin-left: 0;
        margin-right: 0;
    }
}

.badge-icon {
    background-color: $white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-right: $spacing-xs;
    width: $badge-iconsize;
    height: $badge-iconsize;

    line-height: 0;
    vertical-align: middle;

    transition: color 0.1s;
}

.badge--outline-light {
    background-color: $white;
    color: $color-text;
    border: 1px solid $border-color;

    @at-root {

        a#{&},
        button#{&} {
            &:hover, &:focus {
                background-color: $color-primary-lightest;
                color: $color-text;
            }

            &:active {
                background-color: $color-primary-lighter;
                color: $color-text;
            }

            // &:focus-visible {
            //     outline-color: $color-primary-lightest;
            // }
        }
    }
}

.badgegroup {
    display: flex;
    align-items: center;
}

.badgegroup-label {
    margin-right: $spacing-md;
    margin-bottom: $spacing-sm;
}