@use 'settings/global' as *;
@use 'tools' as *;

$loader-size: 4rem;
$loader-color: no-repeat linear-gradient($color-primary 0 0);
$loader-background-size: 1.6rem;

.loader {
    // margin-top: $spacing-md;
    // margin-bottom: $spacing-lg;

    &.loader--fixed {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 110;
        // max-width: 40rem;
        // padding: 0 $spacing-md;
        width: 100%;
        max-height: 100vh;
        overflow-y: auto;
        transform: translate(-50%, -50%);

        @include mq('md') {
            max-width: 50rem;
        }
    }
}

.loader-body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;

    .loader--fixed & {
        background-color: $white;
        padding: $spacing-lg;
        border: 1px solid $border-color;
        border-radius: $borderradius-md;
        margin: 0 $spacing-md;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.loader-animation {
    margin-bottom: $spacing-md;
    background:
        $loader-color, $loader-color, $loader-color,
        $loader-color, $loader-color, $loader-color,
        $loader-color, $loader-color, $loader-color;
    background-size: $loader-background-size $loader-background-size;
    animation:
        l32-1 1s infinite,
        l32-2 1s infinite;
}

@keyframes l32-1 {
    0%, 100% {
        width: 4.5rem;
        height: 4.4rem
    }

    35%, 65% {
        width: 6.5rem;
        height: 6.5rem;
    }
}

@keyframes l32-2 {
    0%, 40% {
        background-position: 0 0, 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 50% 50%
    }

    60%, 100% {
        background-position: 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 0 0, 50% 50%
    }
}