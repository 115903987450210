@use 'settings/global' as *;
@use 'tools' as *;
@use 'components/core/button' as *;

$pagination-height: $button-height !default;
$pagination-lineheight: 1.1 !default;
$pagination-fontfamily: $fontfamily-body;
$pagination-fontsize: $fontsize-body-md !default;
$pagination-padding-y: calc(($pagination-height - ($pagination-fontsize * $pagination-lineheight)) / 2) !default;
$pagination-padding-x: $spacing-md !default;
$pagination-border-radius: 0 !default;
$pagination-border-width: 0.2rem;

.pagination {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-lg;
}

.pagination-list {
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-right: $spacing-2xs;
    }
}

.pagination-page {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: auto;
    overflow: visible;

    padding-top: $pagination-padding-y;
    padding-bottom: $pagination-padding-y;
    padding-left: $pagination-padding-x;
    padding-right: $pagination-padding-x;

    line-height: $pagination-lineheight;
    vertical-align: middle;
    min-width: $pagination-height;

    background-color: $color-primary-lightest;
    border: 0;
    border-radius: $pagination-border-radius;
    color: $color-text;

    font-family: $pagination-fontfamily;
    font-size: $pagination-fontsize;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;

    &.is-active {
        color: $white;
        background-color: $color-primary;
    }
}

a.pagination-page {
    background-color: transparent;
    color: $color-text;
    outline-offset: 1px;
    transition: background-color 0.1s, border-color 0.1s, color 0.1s;

    &:hover, &:focus {
        color: $white;
        background-color: $color-primary;
        text-decoration: none;
    }

    &:active {
        color: $color-primary-dark;
        background-color: transparent;
    }

    &:focus-visible {
        @include focus-visible();
    }

    .contrast-element & {
        color: $white;
        background-color: $color-primary-darkest;

        &:hover, &:focus {
            color: $white;
            background-color: $color-primary-darker;
        }

        &:active {
            color: $white;
            background-color: $color-primary-darkest;
        }

        &:focus-visible {
            outline-color: $white;
        }
    }
}

.pagination-prev,
.pagination-next {
    display: inline-block;
    margin: 0;
    width: auto;
    overflow: visible;
    text-decoration: none;

    > .icon {
        color: $color-primary;
    }

    &:hover, &:focus {
        text-decoration: underline;

        > .icon {
            color: $color-secondary;
        }
    }
}

// .pagination-prev {
//     @include mq('sm') {
//         margin-right: $spacing-md;
//     }
// }

// .pagination-next {
//     @include mq('sm') {
//         margin-left: $spacing-md;
//     }
// }

.pagination-label {
    @include visually-hidden();
}