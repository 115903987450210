@use 'settings/global' as *;
@use 'tools' as *;

.banner-container {
    padding-top: $spacing-md;
    padding-bottom: $spacing-md;
    background-color: $color-primary-lightest;
}

.banner {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    @include max-width-breakpoints();
}

.banner-headline {
    margin-bottom: $spacing-sm;
    font-size: $fontsize-heading-sm;
}