@use 'settings/global' as *;
@use 'tools' as *;

.cart-row {
    margin-bottom: $spacing-md;
    padding-bottom: $spacing-md;

    border-bottom: 1px solid $border-color;
}

.cart-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    > * + * {
        padding-left: $spacing-xl;
    }

    @include mq('lg') {
        align-items: center;
    }
}

.cart-item-media {
    padding: $spacing-xs;
    flex: 0 0 auto;
    width: 8.5rem;

    border-radius: $borderradius-md;
    border: 1px solid $border-color;
}

.cart-item-image {
    width: 100%;

    // @TODO Tristan fix this when image gets cropped correctly from cms.
    width: 8.5rem;
    height: 8.5rem;
    object-fit: cover;
    // End TODO
}

.cart-item-body {
    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;

        > * + * {
            padding-left: $spacing-xl;
        }
    }

    @include mq('lg') {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.cart-description {
    color: $color-text-light;

    @include mq('md') {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.cart-item-headline {
    margin-bottom: $spacing-sm;
    font-size: $fontsize-body-lg;
}

.cart-item-amount {
    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > * + * {
            padding-left: $spacing-xl;
        }
    }
}

.cart-item-integer {
    @include mq('sm') {
        width: 12rem;
    }
}

.cart-item-price {
    @include mq(0, 'md') {
        > * + * {
            margin-top: $spacing-sm;
        }
    }

    @include mq('md') {
        margin-bottom: $spacing-md;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > * + * {
            padding-left: $spacing-lg;
        }
    }
}

.cart-item-subtotal,
.cart-item-total {
    white-space: nowrap;
}


// .cart-item-start,
// .cart-item-end {
//     display: flex;
//     margin-bottom: $spacing-md;

//     @include mq('md') {
//         align-items: center;
//         margin-bottom: 0;
//     }
// }

// .cart-item-start {
//     flex: 1 1 auto;
//     flex-flow: row nowrap;
//     align-items: flex-start;
//     // justify-content: flex-end;

//     > * + * {
//         padding-left: $spacing-xl;
//     }
// }

// .cart-item-end {
//     flex-flow: column nowrap;

//     // flex: 0 0 25%;
//     // justify-content: flex-start;
//     // align-items: center;
// }