@use 'settings/global' as *;
@use 'tools' as *;

/*
 *  Grid
 *  <Based> on Bootstrap's grid
 *  @link http://getbootstrap.com/css/#grid
 */

$grid-show-outlines: false !default;

@if $grid-show-outlines {
    [class*="col-"] {
        outline: 1px dotted LightSalmon;
    }
}

.row {    
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    
    @include max-width-breakpoints();

    .row {
        margin-left: -$spacing-md;
        margin-right: -$spacing-md;
    }

    &.row--narrow {
        @include mq('lg') {
            max-width: $screen-lg;
        }
    }

    &.row--reverse {
        flex-flow: row-reverse wrap;
    }

    &.row--center {
        justify-content: center;
    }
}

.row-fluid {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .row {
        margin-left: -$spacing-md;
        margin-right: -$spacing-md;
    }

    &.row--narrow {
        @include mq('lg') {
            max-width: $screen-lg;
        }
    }

    &.row--reverse {
        flex-flow: row-reverse wrap;
    }

    &.row--center {
        justify-content: center;
    }
}

.row-align-center {
    align-items: center;
}

[class*="col-"],
.col-full {
    position: relative;
    width: 100%;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    flex: 0 0 auto;
    max-width: 100%;
}

.col-content-grow {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;

    > * {
        flex: 0 1 auto;
    }
}

$grid-ratio: calc(100% / $rowcol-columns);

@mixin gridCreateCols($size, $grid-ratio, $rowcol-columns) {
    @for $i from 1 through $rowcol-columns {
        .col-#{$size}-#{$i} {
            //calc function for decimal rounding 'bugs' in different browsers;
            width: $grid-ratio * $i;
            //width: calc(100% / #{$rowcol-columns} * #{$i});
        }

        .col-#{$size}-offset-#{$i} {
            margin-left: $grid-ratio * $i;
            //margin-left: calc(100% / #{$rowcol-columns} * #{$i});
        }

        .col-#{$size}-pull-#{$i} {
            right: $grid-ratio * $i;
        }

        .col-#{$size}-push-#{$i} {
            left: $grid-ratio * $i;
        }
    }
}

@each $size, $min-width in $rowcol-breakpoints {
    @if $min-width > 0 {
        @include mq($min-width) {
            @include gridCreateCols($size, $grid-ratio, $rowcol-columns);
        }
    }

    @else {
        @include gridCreateCols($size, $grid-ratio, $rowcol-columns);
    }
}
