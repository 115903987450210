@use 'settings/global' as *;
@use 'tools' as *;

// @include add-breakpoint(buttongroup, map-get($breakpoints, 'sm'));

.buttongroup {
    margin-top: $spacing-lg;
    // margin-bottom: $spacing-md;

    @include mq(0px, 'sm') {
        > .button {
            width: 100%;

            & + .button {
                margin-top: $spacing-md;
            }
        }
    }

    @include mq('sm') {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        margin-bottom: -$spacing-md;
        margin-left: -$spacing-md;

        > .button {
            flex: 0 1 auto;
            margin-bottom: $spacing-md;
            margin-left: $spacing-md;
        }

        &.buttongroup--center {
            justify-content: center;
        }

        &.buttongroup--reverse {
            flex-direction: row-reverse;
        }

        &.buttongroup--spacebetween {
            justify-content: space-between;
        }

        &.buttongroup--end {
            justify-content: flex-end;
        }
    }
}
