@use 'settings/global' as *;
@use 'tools' as *;

.media {
    display: flex;
    flex-flow: column nowrap;

    > * + * {
        margin-bottom: $spacing-md;
    }

    @include mq('md') {
        display: flex;
        flex-flow: row wrap;

        margin-left: -$spacing-md;
        margin-right: -$spacing-md;

        > * {
            margin-bottom: 0;
            padding-left: $spacing-md;
            padding-right: $spacing-md;
        }

        &.media--reverse {
            flex-direction: row-reverse;
        }
    }

    @include mq('lg') {
        margin-left: -$spacing-lg;
        margin-right: -$spacing-lg;
        align-items: center;

        > * {
            padding-left: $spacing-lg;
            padding-right: $spacing-lg;
        }
    }
}

.media-body {
    @include mq(0px, 'md') {
        order: 2;
    }

    @include mq('md') {
        flex: 1 1 auto;
        width: 50%;

        > :last-child {
            margin-bottom: 0;
        }
    }
}

.media-media {
    @include mq(0px, 'md') {
        order: 1;
    }

    @include mq('md') {
        flex: 0 0 auto;
        width: 40%;
    }

    @include mq('lg') {
        width: 50%;
    }

    .media-only & {
        width: 100%;
    }
}

.media-image {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: $borderradius-lg;

    .media-only & {
        @supports (object-fit: cover) {
            object-fit: cover;
            width: 100%;

            @supports (aspect-ratio: 16 / 9) {
                aspect-ratio: 16 / 9;
            }
        }
    }
}