@use 'settings/global' as *;
@use 'tools' as *;

.topbanner-container {
    background-color: $color-primary;

    @include mq('mobilenav') {
        display: none;
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
    }
}

.topbanner {
    margin-left: auto;
    margin-right: auto;

    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    @include max-width-breakpoints();

    @include mq('mobilenav') {
        padding-top: 0;
        padding-bottom: 0;
    }
}