@use 'settings/global' as *;
@use 'elements/misc' as *;
@use 'tools' as *;

/// Utility classes
/// Display helpers
//  .clearfix {
//      @extend %clearfix;
//  }

.display-none,
.hidden, [hidden] {
    display: none;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

// From AlpineJS
[x-cloak] {
    display: none !important;
}

// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L128-L138
.visually-hidden,
.sr-only {
    @include visually-hidden();
}

/// Spacing
.spacing-top-none {
    margin-top: 0;
}

.spacing-top {
    margin-top: $spacing-md;
}

.spacing-top-small {
    margin-top: $spacing-sm;
}

.spacing-top-smaller {
    margin-top: $spacing-xs;
}

.spacing-top-smallest {
    margin-top: $spacing-2xs;
}

.spacing-top-large {
    margin-top: $spacing-lg;
}

.spacing-top-larger {
    margin-top: $spacing-xl;
}

.spacing-top-largest {
    margin-top: $spacing-2xl;
}

.spacing-bottom-none {
    margin-bottom: 0;
}

.spacing-bottom {
    margin-bottom: $spacing-md;
}

.spacing-bottom-small {
    margin-bottom: $spacing-sm;
}

.spacing-bottom-smaller {
    margin-bottom: $spacing-xs;
}

.spacing-bottom-smallest {
    margin-bottom: $spacing-2xs;
}

.spacing-bottom-large {
    margin-bottom: $spacing-lg;
}

.spacing-bottom-larger {
    margin-bottom: $spacing-xl;
}

.spacing-bottom-largest {
    margin-bottom: $spacing-2xl;
}

.spacing-top-lg-none {
    @include mq('lg') {
        margin-top: 0;
    }
}

.spacing-bottom-lg-none {
    @include mq('lg') {
        margin-bottom: 0;
    }
}

/// Text helpers
.text-default {
    color: $color-text;
}

.text-primary {
    color: $color-primary;
}

.text-secondary {
    color: $color-secondary;
}

.text-danger {
    color: $color-danger;
}

.text-success {
    color: $color-success;
}

.text-warning {
    color: $color-warning;
}

.text-muted {
    color: $color-text-light;
}

.text-bold {
    @extend strong;
}

.text-italic {
    @extend em;
}

.text-underline {
    @extend u;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-subscript {
    @extend sub;
}

.text-superscript {
    @extend sup;
}

.text-strike {
    @extend del;
}

.text-small {
    font-size: $fontsize-body-sm;
}

.text-large {
    font-size: $fontsize-body-lg;
}

.text-transform-none {
    text-transform: none;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-break {
    word-wrap: break-word;
    hyphens: auto;
}

.text-nowrap {
    white-space: nowrap;
}

.align-top {
    vertical-align: top;
}

.align-bottom {
    vertical-align: bottom;
}

.align-middle {
    vertical-align: middle;
}

.align-items-center {
    align-items: center;
}

// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
//    See: https://code.google.com/p/chromium/issues/detail?id=457146
.text-hidden {
    overflow: hidden;
    padding: 0;
    text-indent: 101%; // 1
    white-space: nowrap;
}

.responsive-element {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    // padding-bottom: 42.85%; // 21:9 
    // padding-top: 25px;
    height: 0;
    margin-bottom: 0;

    iframe,
    .element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.hide-on-mobile {
    @include mq(0px, 'md') {
        display: none !important;
    }
}

.hide-on-tablet {
    @include mq('md', 'lg') {
        display: none;
    }
}

.hide-on-desktop {
    @include mq('lg') {
        display: none;
    }
}

.order-1 {
    order: -1;
}
