@use 'settings/global' as *;
@use 'tools' as *;
@use 'elements/a' as *;

/// Buttons
$button-height: 4rem !default;
$button-lineheight: 1.1 !default;
$button-fontfamily: $fontfamily-heading;
$button-fontsize: $fontsize-body-md !default;
$button-fontweight: 400 !default;
$button-padding-y: calc(($button-height - ($button-fontsize * $button-lineheight)) / 2) !default;
$button-padding-x: calc($button-height / 2) !default;
$button-borderradius: 0.5rem !default;
$button-borderwidth: 0.2rem;

$button-small-height: 3rem !default;
$button-small-lineheight: 1.1 !default;
$button-small-fontsize: $fontsize-body-sm !default;
$button-small-padding-y: calc(($button-small-height - ($button-small-fontsize * $button-small-lineheight)) / 2) !default;
$button-small-padding-x: calc($button-small-height / 2) !default;
$button-small-borderradius: 0.5rem !default;
$button-small-borderwidth: $button-borderwidth;

@mixin button-reset() {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.button {
    @include button-reset();

    position: relative;
    display: inline-block;

    padding-top: $button-padding-y;
    padding-bottom: $button-padding-y;
    padding-left: $button-padding-x;
    padding-right: $button-padding-x;

    border-radius: $button-borderradius;
    color: $color-text;

    font-family: $button-fontfamily;
    font-size: $button-fontsize;
    font-weight: $button-fontweight;
    font-style: normal;
    line-height: $button-lineheight;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    text-shadow: none;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, outline 0.1s;

    &:hover, &:focus {
        text-decoration: none;
        color: $color-text;
        background-color: $color-subsidiary-lighter;
    }

    &:active {
        text-decoration: none;
        color: $color-text;
        background-color: $color-subsidiary-light;
    }

    &:focus-visible {
        outline-offset: 2px;
    }

    &:disabled {
        background-color: $color-subsidiary-light;
        border-color: $color-subsidiary-light;
        color: $color-text;
        cursor: not-allowed;
        opacity: 0.75;

        &:hover, &:focus, &:active {
            background-color: $color-subsidiary-light;
            border-color: $color-subsidiary-light;
            color: $color-text;
        }
    }

    // &:has(svg) {
    //     > svg.icon {
    //         margin-right: $spacing-sm;
    //     }
    // }
}

.button[class*="button--outline-"],
.button.button--outline {
    padding-top: $button-padding-y - $button-borderwidth;
    padding-bottom: $button-padding-y - $button-borderwidth;

    border-width: $button-borderwidth;
    border-style: solid;
    background-color: transparent;

    &.button--small {
        padding-top: $button-small-padding-y - $button-small-borderwidth;
        padding-bottom: $button-small-padding-y - $button-small-borderwidth;
    }

    &:disabled {
        background-color: transparent;
    }
}

.button--small {
    padding-top: $button-small-padding-y;
    padding-bottom: $button-small-padding-y;
    padding-left: $button-small-padding-x;
    padding-right: $button-small-padding-x;

    border-radius: $button-small-borderradius;
    line-height: $button-small-lineheight;
    font-size: $button-small-fontsize;
}

.button--full {
    width: 100%;
    // white-space: nowrap;

    & + & {
        margin-top: $spacing-sm;
    }
}

.button--link {
    background: transparent;

    @include anchor();

    &:hover, &:focus:not(:focus-visible) {
        background: transparent;
    }

    &:active {
        background: transparent;
    }
}

.button--icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    > .icon {
        margin-right: $spacing-sm;
        position: relative;
        top: 1px;
    }
}

.button.icon-only {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    min-width: $button-height;

    // > .button-icon {
    //     margin-left: 0;
    //     margin-right: 0;
    // }

    &.button--small {
        min-width: $button-small-height;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

// .button--nowrap {
//     @include mq('xs') {
//         white-space: nowrap;
//     }
// }


.button--primary {
    background-color: $color-primary;
    color: $white;

    &:hover, &:focus {
        background-color: $color-primary-dark;
        color: $white;
    }

    &:active {
        background-color: $color-primary-darkest;
        color: $white;
    }
}

.button--secondary {
    background-color: $color-secondary;
    color: $white;

    &:hover, &:focus {
        background-color: $color-secondary-dark;
        color: $white;
    }

    &:active {
        background-color: $color-secondary-darkest;
        color: $white;
    }
}

.button--light {
    background-color: $offwhite;
    color: $color-text;

    &:hover, &:focus {
        background-color: $color-subsidiary-light;
        color: $color-text;
    }

    &:active {
        background-color: $color-subsidiary-lighter;
        color: $color-text;
    }
}

.button--primary-light {
    background-color: $color-primary-light;
    color: $white;

    &:hover, &:focus {
        background-color: $color-primary;
        color: $white;
    }

    &:active {
        background-color: $color-primary-darkest;
        color: $white;
    }
}

.button--outline-primary {
    border-color: $color-primary;
    color: $color-primary;

    &:hover, &:focus {
        background-color: $color-primary;
        color: $white;
        border-color: $color-primary;
    }

    &:active {
        background-color: $color-primary-dark;
        color: $white;
        border-color: $color-primary-dark;
    }
}

.button--outline-secondary {
    border-color: $color-secondary;
    color: $color-secondary;

    &:hover, &:focus {
        background-color: $color-secondary;
        color: $white;
        border-color: $color-secondary;
    }

    &:active {
        background-color: $color-secondary-dark;
        color: $white;
        border-color: $color-secondary-dark;
    }
}

.button--outline-light {
    border-color: $color-subsidiary-lighter;
    color: $color-text;

    &:hover, &:focus {
        background-color: $color-subsidiary-lighter;
        color: $color-text;
        border-color: $color-subsidiary-lighter;
    }

    &:active {
        background-color: $color-subsidiary-lightest;
        color: $color-text;
        border-color: $color-subsidiary-lightest;
    }
}

.button--outline-danger {
    border-color: $color-danger;
    color: $color-danger;

    &:hover, &:focus {
        background-color: $color-danger;
        color: $white;
        border-color: $color-danger;
    }

    &:active {
        background-color: $color-danger-darker;
        color: $white;
        border-color: $color-danger-darker;
    }
}


.button--danger {
    background-color: $color-danger;
    color: $white;

    &:hover, &:focus {
        color: $white;
        background-color: $color-danger-dark;
    }

    &:active {
        color: $white;
        background-color: $color-danger-darker;
    }
}
