@use 'settings/global' as *;
@use 'tools' as *;

.leading {
    margin-bottom: $spacing-lg;

    &.leading--small {
        .leading-headline {
            font-size: $fontsize-heading-lg;

            @include mq('xl') {
                font-size: $fontsize-heading-xl;
            }
        }

        .leading-action {
            margin-top: $spacing-xs;
        }
    }

    &.leading--large {
        .leading-headline {
            font-size: $fontsize-heading-2xl;

            @include mq('xl') {
                font-size: $fontsize-heading-3xl;
            }
        }

        .leading-action {
            margin-top: $spacing-sm;
        }
    }

    &.leading--center {
        text-align: center;
    }

    &.has-action {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &.leading--center {
            justify-content: center;
        }
    }
}

.leading-headline {
    margin-top: 0;
    margin-bottom: 0;

    display: block;

    font-size: $fontsize-heading-2xl;
    font-family: $fontfamily-heading;
    font-style: normal;
    font-weight: 700;
    color: $color-text;
    // line-height: 1.1;
    // text-transform: uppercase;
    // overflow-wrap: break-word;

    .contrast-element & {
        color: $white;
    }

    @include mq('md') {
        flex: 0 1 auto;
    }

    // @include mq('lg') {
    //     font-size: $fontsize-heading-2xl;
    // }
}

.leading-action {
    flex: 0 1 auto;
    margin-left: auto;
    padding-left: $spacing-md;
}

.leading-tagline {
    display: block;
    font-family: $fontfamily-heading;
    font-size: $fontsize-heading-md;
    font-weight: 600;
    font-style: normal;
    // line-height: 1.1;
    color: $color-text;
    margin-top: $spacing-sm;
}
