@use 'settings/global' as *;
@use 'tools' as *;
@use 'components/core/button' as *;

$social-height: $button-height !default;
$social-lineheight: 1.1 !default;
$social-border-radius: $borderradius-md !default;
$social-border-width: 0.2rem;

.social {
    display: inline-flex;
}

.social-links {
    display: flex;
    flex-wrap: wrap;

    :last-child {
        margin-right: 0;
    }
}

.social-link {
    // position: relative;
    // display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $social-height;
    height: $social-height;
    overflow: visible;

    margin-top: $spacing-2xs;
    margin-left: $spacing-2xs;
    margin-right: $spacing-2xs;
    padding: $spacing-xs;

    font-family: $fontfamily-body;
    font-size: $fontsize-body-md;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;

    transition: background-color 0.1s, border-color 0.1s, color 0.1s;

    background-color: $color-primary-light;
    border: 0;
    border-radius: $social-border-radius;
    color: $white;

    &:hover, &:focus {
        color: $white;
        background-color: $color-primary-dark;
    }

    &:active {
        color: $white;
        background-color: $color-primary-darker;
    }

    // &:focus-visible {
    //     @include focus-visible();
    // }

    .contrast-element & {
        color: $color-text;
        background-color: $white;

        &:hover, &:focus {
            color: $color-primary;
            background-color: $color-primary-lightest;
        }

        &:active {
            color: $color-primary-dark;
            background-color: $color-primary-lighter;
        }

        // &:focus-visible {
        //     outline-color: $color-primary-lightest;
        // }
    }
}

// .social-link-icon {
    // margin: 0;
    // line-height: 0;
    // color: $color-secondary; // when specified social colors below are not defined.
// }

.social-label {
    @include visually-hidden();
}

// .social-link-label {
//     @include visually-hidden();
// }

// .social-link--facebook {
//     &:first-child {
//         margin-left: -0.3em;
//     }
// }

// .social-link-email {
//     &:hover,
//     &:focus {
//     }
// }

@mixin social-link-icon-hover {
    .social-link-icon {
        color: $white;
    }
}

.social-link--facebook {
    color: $color-facebook;

    &:hover, &:focus {
        background-color: $color-facebook;
        @include social-link-icon-hover;
    }
}

.social-link--twitter {
    color: $color-xtwitter;

    &:hover, &:focus {
        background-color: $color-xtwitter;
        @include social-link-icon-hover;
    }
}

.social-link--instagram {
    color: $black;

    &:hover, &:focus {
        background-image: $gradient-instagram;
        @include social-link-icon-hover;
    }
}

.social-link--youtube {
    color: $color-youtube;

    &:hover, &:focus {
        background-color: $color-youtube;
        @include social-link-icon-hover;
    }
}

.social-link--linkedin {
    color: $color-linkedin;

    &:hover, &:focus {
        background-color: $color-linkedin;
        @include social-link-icon-hover;
    }
}

.social-link--pinterest {
    color: $color-pinterest;

    &:hover, &:focus {
        background-color: $color-pinterest;
        @include social-link-icon-hover;
    }
}

.social-link--dribbble {
    color: $color-dribbble;

    &:hover, &:focus {
        background-color: $color-dribbble;
        @include social-link-icon-hover;
    }
}

.social-link--tiktok {
    color: $black;

    &:hover, &:focus {
        background-image: $gradient-tiktok;
        @include social-link-icon-hover;
    }
}