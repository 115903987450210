@use 'settings/global' as *;
@use 'settings/form-config' as *;
@use 'tools' as *;

.form-container {
    padding: $spacing-md;
    background-color: $form-background-color;
    border-radius: $form-border-radius;

    @include mq('md') {
        padding: $spacing-xl;
    }

    > :last-child {
        margin-bottom: 0;
    }

    &.is-bordered {
        border: 1px solid $color-primary-lighter;
    }
}

.form-group {
    &:not(:first-child) {
        margin-top: $spacing-2xl;
        // margin-top: $spacing-md * 2.5;
        // padding-top: $spacing-md * 2.5;
        // border-top: 1px solid $color-subsidiary-lighter;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.form-section {
    margin-bottom: $spacing-xl;
}

.form-row {
    margin-bottom: $form-row-margin;

    &.form-row-invalid {
        .form-control {

            #{$form-input-selectors},
            .checkbox-indicator,
            select,
            textarea {
                border-color: $color-danger;
                outline: 1px solid $color-danger;

                &:focus {
                    border-color: $color-danger;
                    outline: 2px solid $color-danger;

                    & ~ .form-label {
                        color: $color-danger;
                    }
                }
            }

            .checkbox-indicator {
                border-color: $color-danger;
            }
        }
    }
}

.form-label {
    display: block;
    margin-bottom: $form-label-spacing;

    font-family: $form-label-fontfamily;
    font-size: $form-label-fontsize;
    font-weight: $form-label-fontweight;
    line-height: 1;
    color: $form-label-color;
    min-height: $form-label-fontsize;

    &.required {
        &::after {
            content: "";
            display: inline-block;
            background-color: $color-danger;
            border-radius: 50%;
            width: 0.6rem;
            height: 0.6rem;
            position: relative;
            top: -2px;
            right: -2px;
        }
    }

    .contrast-element & {
        color: $white;
    }

    .hide-labels & {
        @include visually-hidden();
    }
}

.form-control {
    position: relative;

    #{$form-input-selectors},
    select,
    textarea {
        position: relative;
        appearance: none;
        -webkit-appearance: none;

        border: $form-border;
        border-color: $form-border-color;
        border-radius: $form-border-radius;
        background-color: $form-input-background;

        padding-top: $spacing-2xs;
        padding-bottom: $spacing-2xs;
        padding-left: $form-padding-x;
        padding-right: $form-padding-x;

        height: $form-height;
        width: 100%;
        max-width: 100%;
        min-width: 0;
        vertical-align: middle;

        font-size: $fontsize-body-md;
        font-weight: $fontweight-body;
        line-height: 1.3;
        color: $color-text;

        transition: border-color 0.1s, box-shadow 0.1s, outline 0.1s;

        &:focus {
            color: $color-text;
            border-color: $form-focus-color;
            outline: $form-border-focus-outline;
            z-index: 1;
        }

        &:-webkit-autofill::first-line {
            font-size: $fontsize-body-md;
        }

        &:disabled {
            background-color: $color-subsidiary-lightest;
            border-color: $color-subsidiary-lightest;
            color: $color-text-light;
            cursor: not-allowed;
        }
    }

    [type="password"] {
        &::-ms-reveal {
            display: none;
        }
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;

        padding-right: ($form-padding-x * 2 + 1rem);

        background-image: icon("chevron-down");
        background-repeat: no-repeat;
        background-position: right $form-padding-x center;
        background-size: 1.8rem 1.8rem;
        background-color: $form-input-background;

        &::-ms-expand {
            display: none;
        }
    }

    textarea {
        padding-top: 0.5em;
        resize: vertical;
        min-height: 8em;
        line-height: $lineheight-body;
    }

    :focus {
        & ~ .form-label {
            color: $form-focus-color;
        }
    }

    &.has-button {
        display: flex;
        flex-flow: row nowrap;

        > .button {
            margin-left: $spacing-sm;
        }
    }
}

.form-text {
    margin-top: $spacing-sm;
}

.form-separator {
    line-height: $form-height - 0.2rem;
}

.form-rows-inline {
    margin-bottom: $spacing-md;

    > .form-row-floating-label {
        margin-bottom: 0.5rem;
    }

    > .form-row-floating-label + .form-row-floating-label {
        margin-top: 1.4rem;
        margin-bottom: 1.4rem;
    }

    .button {
        white-space: nowrap;
    }

    @include mq('md') {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;

        > .form-row {
            flex: 1 1 100%;
            margin-bottom: 0;
        }

        > .form-row--shrink {
            flex: 0 1 auto;
        }

        > .form-row + .form-row {
            margin-left: $spacing-md;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.form-row--shrink > .form-control > select {
    min-width: 7rem;
}

// .check-input {
//     position: relative;

//     [type="radio"],
//     [type="checkbox"] {
//         position: absolute;
//         opacity: 0;
//         z-index: -1;

//         &:checked ~ .check-input-indicator {
//             border-color: $color-primary !important;
//             background-color: $color-primary;
//             background-image: none;

//             &::after {
//                 opacity: 1;
//                 transform: scale(1);
//             }
//         }

//         &:focus ~ .check-input-indicator {
//             border-color: $form-focus-color;
//             outline: none;
//         }

//         &:focus-visible ~ .check-input-indicator {
//             @include focus-visible();
//         }

//         &[disabled] ~ .check-input-indicator {
//             opacity: 0.6;
//         }

//         &[disabled]:not([checked]) ~ .check-input-indicator {
//             border-color: $color-primary-lighter;
//             background-color: $color-primary-lightest;
//             color: $color-text;
//         }
//     }
// }

// .check-input-group {
//     @include mq('sm') {
//         display: flex;
//         flex-flow: row wrap;
//         justify-content: flex-start;
//         align-items: flex-start;

//         > * {
//             flex: 0 1 auto;
//             width: calc(50% - #{$spacing-md});

//             &:nth-child(even) {
//                 margin-left: $spacing-md;
//             }
//         }

//         .check-input-label {
//             margin-bottom: $spacing-sm;
//         }
//     }

//     .check-input-label {
//         display: block;
//     }
// }

// .check-input-label {
//     display: inline-flex;
//     flex-flow: row nowrap;
//     align-items: center;
//     line-height: $lineheight-body;
//     cursor: pointer;
//     // margin-bottom: $spacing-sm;
//     //overflow:hidden;

//     &.is-disabled {
//         color: $color-text-light;
//     }

//     > .check-input {
//         display: inline-block;
//         margin-right: $spacing-sm;
//         flex: 0 0 auto;
//     }

//     > .check-input-text {
//         flex: 1 1 auto;
//     }

//     .custom-controls-stacked.check-input-inline & {
//         margin-right: $spacing-md;
//     }

//     .custom-controls-stacked:not(.check-input-inline) & {
//         display: flex;
//         margin-bottom: 0.3rem;
//     }
// }

// .check-input-text {
//     display: inline-flex;
//     flex-flow: column nowrap;
// }

// .check-input-indicator {
//     position: relative;
//     display: inline-block;
//     top: -1px;
//     width: 2rem;
//     height: 2rem;
//     line-height: 1;

//     overflow: hidden;
//     pointer-events: none;
//     vertical-align: middle;

//     border: $form-border $form-border-color;
//     border-radius: $form-border-radius;
//     background-color: $form-input-background;

//     transition: border-color 0.1s ease-in;

//     &:not(:last-child) {
//         margin-right: 0.4em;
//     }

//     [type="radio"] ~ & {
//         border-radius: 50%;

//         &:after {
//             border-radius: 50%;
//         }
//     }

//     [type="checkbox"] ~ & {
//         &::after {
//             content: "";
//             top: 1px;
//             right: 1px;
//             bottom: 1px;
//             left: 1px;

//             font-size: $fontsize-body-lg;
//             color: $form-focus-color;

//             mask-image: icon("check");
//             mask-repeat: no-repeat;
//             mask-size: 100% auto;
//             background-color: $white;
//         }
//     }

//     &::after {
//         content: "";
//         position: absolute;
//         top: 4px;
//         right: 4px;
//         bottom: 4px;
//         left: 4px;
//         text-align: center;
//         background-color: $white;

//         opacity: 0;
//         transform: scale(0);

//         transition: opacity 0.1s, transform 0.05s ease-in;
//     }
// }

.form-errors {
    font-size: $form-error-fontsize;
    font-weight: $form-error-fontweight;
    line-height: 1.3;
    margin-top: $spacing-xs;
    color: $color-danger;

    .form-control & {
        margin-top: $spacing-xs;
        margin-bottom: 0;
    }

    .notification-danger & {
        color: inherit;
    }
}

.form-control-icon {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 2;

    display: inline-block;
    width: $form-height;
    height: $form-height - 0.4rem;
    line-height: $form-height - 0.4rem;
    text-align: center;
    color: $color-subsidiary;

    border: none;
    border-radius: $form-border-radius 0 0 $form-border-radius;
    background-color: transparent;

    &:not(.has-button) {
        pointer-events: none;
        background-color: transparent;
    }

    .form-row-floating-label & {
        top: 0.7rem;
    }
}

.form-control-icon-button {
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background-color: $form-input-background;
    border: 0;
    border-radius: $form-border-radius;
    cursor: pointer;

    height: inherit;
    width: inherit;
    line-height: inherit;
    color: $color-primary-light;

    &:hover, &:focus {
        color: $color-primary;
    }

    > .icon {
        width: auto;
    }
}

.form-control-icon-start {
    left: 2px;
    right: auto;

    & ~ [type="text"],
    & ~ [type="search"],
    & ~ [type="number"] {
        padding-left: $form-height;
    }

    & ~ select {
        padding-left: $form-height - 0.4rem;
    }
}

.form-control-icon-end {
    left: auto;
    right: 2px;
    border-radius: 0 $form-border-radius $form-border-radius 0;

    & ~ [type="text"],
    & ~ [type="search"] {
        padding-right: $form-height;
    }

    & ~ select {
        padding-right: $form-height - 0.4rem;
    }
}

.passwordtoggle {
    color: $color-text-light;

    transition: color 0.1s;

    &:hover, &:focus {
        color: $color-primary;
    }

    &:active {
        color: $color-primary-darker;
    }
}

.passwordtoggle-icon {
    display: none;
}

[type="password"] ~ .form-control-icon .passwordtoggle-icon.is-password {
    display: block;
}

[type="text"] ~ .form-control-icon .passwordtoggle-icon.is-text {
    display: block;
}
