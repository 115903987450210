@use 'settings/global' as *;
@use 'tools' as *;

.skipper {
    position: fixed;
    z-index: 999;

    &.skipper--top {
        top: 0;
        left: 0;
        margin-left: $spacing-md;
        padding: $spacing-md;
        background-color: $color-subsidiary-darkest;

        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);

        > a {
            display: block;
            color: $white;
        }

        &:focus-within {
            transform: translateY(0);
        }
    }

    &.skipper--bottom {
        bottom: $spacing-md;
        right: $spacing-md;
        opacity: 0;

        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        transform: translateX(100%);

        &:focus-within,
        &.is-visible {
            opacity: 1;
            transform: translateX(0);
        }
    }
}