@use 'settings/global' as *;

@use "sass:math";
@use "sass:map";
@use "sass:list";


/// Breakpoint list
///
/// Name your breakpoints in a way that creates a ubiquitous language
/// across team members. It will improve communication between
/// stakeholders, designers, developers, and testers.
///
/// @type Map
/// @link https://github.com/sass-mq/sass-mq#seeing-the-currently-active-breakpoint Full documentation and examples
$breakpoints: (mobile: 320px,
        tablet: 740px,
        desktop: 980px,
        wide: 1300px,
    ) !default;

/// Show breakpoints in the top right corner
///
/// If you want to display the currently active breakpoint in the top
/// right corner of your site during development, add the breakpoints
/// to this list, ordered by width. For example: (mobile, tablet, desktop).
///
/// @example scss
///   @use 'path/to/mq' with ($show-breakpoints: ('mobile', 'tablet', 'desktop'));
///
///
/// @type map
$show-breakpoints: () !default;

/// Customize the media type (for example: `@media screen` or `@media print`)
/// By default sass-mq uses an "all" media type (`@media all and â€¦`)
///
/// If you want to overried the media type, you can use this option.
/// @example scss
///   @use 'path/to/mq' with ($media-type: 'screen');
///
/// @type String
/// @link https://github.com/sass-mq/sass-mq#changing-media-type Full documentation and example
$media-type: all !default;

/// Convert pixels to ems
///
/// @param {Number} $px - value to convert
///
/// @example scss
///  $fontsize-body-in-ems: px2em(16px);
///  p { font-size: px2em(16px); }
///
/// @returns {Number}

@function px2em($px) {
    @if math.is-unitless($px) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
        @return px2em($px * 1px);
    }

    // if $px is compatible with em units, then return value unchanged
    @if math.compatible($px, 1em) {
        @return $px;
    }

    @return math.div($px, 16px) * 1em;
}

/// Get a breakpoint's width
///
/// @param {String} $name - Name of the breakpoint. One of $breakpoints
///
/// @example scss
///  $tablet-width: get-breakpoint-width(tablet);
///  @media (min-width: get-breakpoint-width(tablet)) {}
///
/// @requires {Variable} $breakpoints
///
/// @returns {Number} Value in pixels
@function get-breakpoint-width($name, $breakpoints: $breakpoints) {
    @if map.has-key($breakpoints, $name) {
        @return map.get($breakpoints, $name);
    }

    @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
        @return null;
    }
}

/// Media Query mixin
///
/// @param {String | Boolean} $from [false] - One of $breakpoints
/// @param {String | Boolean} $until [false] - One of $breakpoints
/// @param {String | Boolean} $and [false] - Additional media query parameters
/// @param {String} $media-type [$media-type] - Media type: screen, printâ€¦
///
/// @ignore Undocumented API, for advanced use only:
/// @ignore @param {Map} $breakpoints [$breakpoints]
///
/// @content styling rules, wrapped into a @media query when $responsive is true
///
/// @requires {Variable} $media-type
/// @requires {Variable} $breakpoints
/// @requires {function} px2em
/// @requires {function} get-breakpoint-width
///
/// @link https://github.com/sass-mq/sass-mq#responsive-mode-on-default Full documentation and examples
///
/// @example scss
///  @use 'path/to/mq' as *;
///  .element {
///    @include mq($from: mobile) {
///      color: red;
///    }
///    @include mq($until: tablet) {
///      color: blue;
///    }
///    @include mq(mobile, tablet) {
///      color: green;
///    }
///    @include mq($from: tablet, $and: '(orientation: landscape)') {
///      color: teal;
///    }
///    @include mq(950px) {
///      color: hotpink;
///    }
///    @include mq(tablet, $media-type: screen) {
///      color: hotpink;
///    }
///    // Advanced use:
///    $my-breakpoints: (L: 900px, XL: 1200px);
///    @include mq(L, $breakpoints: $my-breakpoints) {
///      color: hotpink;
///    }
///  }
@mixin mq($from: false,
    $until: false,
    $and: false,
    $media-type: $media-type,
    $breakpoints: $breakpoints) {
    $min-width: 0;
    $max-width: 0;
    $media-query: '';

    // From: this breakpoint (inclusive)
    @if $from {
        @if type-of($from)==number {
            $min-width: px2em($from);
        }

        @else {
            $min-width: px2em(get-breakpoint-width($from, $breakpoints));
        }
    }

    // Until: that breakpoint (exclusive)
    @if $until {
        @if type-of($until)==number {
            $max-width: px2em($until);
        }

        @else {
            $max-width: px2em(get-breakpoint-width($until, $breakpoints)) - 0.01em;
        }
    }

    @if $min-width !=0 {
        $media-query: '#{$media-query} and (min-width: #{$min-width})';
    }

    @if $max-width !=0 {
        $media-query: '#{$media-query} and (max-width: #{$max-width})';
    }

    @if $and {
        $media-query: '#{$media-query} and #{$and}';
    }

    // Remove unnecessary media query prefix 'all and '
    @if ($media-type =='all' and $media-query !='') {
        $media-type: '';
        $media-query: str-slice(unquote($media-query), 6);
    }

    @media #{$media-type + $media-query} {
        @content;
    }
}

/// Quick sort
///
/// @author Sam Richards
/// @access private
/// @param {List} $list - List to sort
/// @returns {List} Sorted List
@function _quick-sort($list) {
    $less: ();
    $equal: ();
    $large: ();

    @if length($list) > 1 {
        $seed: list.nth($list, math.ceil(math.div(length($list), 2)));

        @each $item in $list {
            @if ($item ==$seed) {
                $equal: list.append($equal, $item);
            }

            @else if ($item < $seed) {
                $less: list.append($less, $item);
            }

            @else if ($item > $seed) {
                $large: list.append($large, $item);
            }
        }

        @return join(join(_quick-sort($less), $equal), _quick-sort($large));
    }

    @return $list;
}

/// Sort a map by values (works with numbers only)
///
/// @access private
/// @param {Map} $map - Map to sort
/// @returns {Map} Map sorted by value
@function _map-sort-by-value($map) {
    $map-sorted: ();
    $map-keys: map.keys($map);
    $map-values: map.values($map);
    $map-values-sorted: _quick-sort($map-values);

    // Reorder key/value pairs based on key values
    @each $value in $map-values-sorted {
        $index: index($map-values, $value);
        $key: list.nth($map-keys, $index);
        $map-sorted: map.merge($map-sorted,
                ($key: $value,
                ));

        // Unset the value in $map-values to prevent the loop
        // from finding the same index twice
        $map-values: list.set-nth($map-values, $index, 0);
    }

    @return $map-sorted;
}

/// Add a breakpoint
///
/// @param {String} $name - Name of the breakpoint
/// @param {Number} $width - Width of the breakpoint
///
/// @requires {Variable} $breakpoints
///
/// @example scss
///  @include add-breakpoint(tvscreen, 1920px);
///  @include mq(tvscreen) {}
@mixin add-breakpoint($name, $width) {
    $new-breakpoint: ($name: $width,
        );
    $breakpoints: map.merge($breakpoints, $new-breakpoint) !global;
    $breakpoints: _map-sort-by-value($breakpoints) !global;
}

/// Show the active breakpoint in the top right corner of the viewport
/// @link https://github.com/sass-mq/sass-mq#seeing-the-currently-active-breakpoint
///
/// @param {List} $show-breakpoints [$show-breakpoints] - List of breakpoints to show in the top right corner
/// @param {Map} $breakpoints [$breakpoints] - Breakpoint names and sizes
///
/// @requires {Variable} $breakpoints
/// @requires {Variable} $show-breakpoints
///
/// @example scss
///  // Show breakpoints using global settings
///  @include show-breakpoints;
///
///  // Show breakpoints using custom settings
///  @include show-breakpoints((L, XL), (S: 300px, L: 800px, XL: 1200px));
@mixin show-breakpoints($show-breakpoints: $show-breakpoints,
    $breakpoints: $breakpoints) {
    body:before {
        background-color: #fcf8e3;
        border-bottom: 1px solid #fbeed5;
        border-left: 1px solid #fbeed5;
        color: #c09853;
        font: small-caption;
        padding: 3px 6px;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;

        // Loop through the breakpoints that should be shown
        @each $show-breakpoint in $show-breakpoints {
            $width: get-breakpoint-width($show-breakpoint, $breakpoints);

            @include mq($show-breakpoint, $breakpoints: $breakpoints) {
                content: '#{$show-breakpoint} â‰¥ #{$width} (#{px2em($width)})';
            }
        }
    }
}

@if list.length($show-breakpoints) > 0 {
    @include show-breakpoints;
}